import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import { useState } from "react";
import Preloader from "../../components/preloader";
import BoostModal from "../../components/boostModal";

import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";

function Raid() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [modalOpened, setModalOpened] = useState(false);
  const [factionType, setFactionType] = useState("All");
  const [landType, setLandtype] = useState("Zomb Heavy");
  const [landFaction, setLandFaction] = useState("Zombie");
  const [cinput, setCinput] = useState(1);
  const [combinations, setCombinations] = useState([]);

  const handleInput = (e) => {
    var value = e.target.value;
    if (value < 1) value = 1;
    setCinput(value);
  };

  const addCombination = (combination) => {};

  const removeCombination = (combination) => {
    var combinations = combinations.slice();
    var index = combinations.indexOf(combination);
    if (index > -1) {
      combinations.splice(index, 1);
    }
    setCombinations(combinations);
  };

  const findAsset = (asset, allCombinations) => {
    var found = false;
    for (var i = 0; i < allCombinations.length; i++) {
      if (allCombinations[i].character === asset) {
        found = true;
        break;
      }
      if (allCombinations[i].weapon === asset) {
        found = true;
        break;
      }
      if (allCombinations[i].commander === asset) {
        found = true;
        break;
      }
      if (allCombinations[i].companion === asset) {
        found = true;
        break;
      }
    }
    return found;
  };

  function autoSelect(currentCombinations) {
    var assets = AccountStore.userAssets;
    var characters = assets.characters
      .slice()
      .filter((c) => findAsset(c, currentCombinations) === false)
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var characterFaction = "none";
    var weapons = assets.weapons
      .slice()
      .filter((c) => findAsset(c, currentCombinations) === false)
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var commanders = assets.commanders
      .slice()
      .filter((c) => findAsset(c, currentCombinations) === false)
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var companions = assets.companions
      .slice()
      .filter((c) => findAsset(c, currentCombinations) === false)
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var selected = {
      character: null,
      weapon: null,
      commander: null,
      companion: null,
      boost: null,
    };
    var selectedCharacter = null;
    var selectedWeapon = null;
    var selectedCommander = null;
    var selectedCompanion = null;

    for (var i = 0; i < characters.length; i++) {
      var boosts = FunctionStore.split_boosts(characters[i].stakedData);
      var rboost = boosts.rboost;
      var nboost = boosts.nboost;
      var tboost = boosts.tboost;
      if (rboost === 0) continue;
      var faction = characters[i].stakedData.faction;
      characterFaction = faction;
      var characterFactionName =
        characterFaction == 1
          ? "Zombie"
          : characterFaction == 2
          ? "Reborn"
          : characterFaction == 3
          ? "Hunter"
          : "none";
      if (factionType !== "All" && factionType !== characterFactionName)
        continue;
      selectedCharacter = characters[i];
      selectedCharacter.power = rboost;
      break;
    }
    for (var i = 0; i < weapons.length; i++) {
      var weaponFaction = weapons[i]?.stakedData?.faction;
      if (!weaponFaction || characterFaction === "none") continue;
      if (weaponFaction === characterFaction || weaponFaction === 0) {
        selectedWeapon = weapons[i];
        selectedWeapon.power = FunctionStore.split_boosts(
          weapons[i].stakedData
        );
        break;
      }
    }
    for (var i = 0; i < commanders.length; i++) {
      var commanderFaction = commanders[i]?.stakedData?.faction;
      if (!commanderFaction || characterFaction === "none") continue;
      if (commanderFaction === characterFaction || commanderFaction === 0) {
        selectedCommander = commanders[i];
        selectedCommander.power = FunctionStore.split_boosts(
          commanders[i].stakedData
        );
        break;
      }
    }

    selectedCompanion = companions.length > 0 ? companions[0] : null;
    selected.character = selectedCharacter;
    selected.weapon = selectedWeapon;
    if (selectedCommander) selected.commander = selectedCommander;
    if (selectedCompanion) selected.companion = selectedCompanion;

    console.log(selected);
    for (var i = 0; i < selected.length; i++) {
      console.log(selected[i]?.power?.toString());
    }

    return selected;
  }

  const getTotalCombinations = () => {
    return combinations.length;
  };

  const getTotalCost = () => {
    var TotalCost = 0;
    var counts = AccountStore.getRaidCostArray();
    var totalcombinations = cinput;
    counts = counts.slice(0, totalcombinations);
    for (var i = 0; i < counts.length; i++) {
      TotalCost += counts[i];
    }
    return TotalCost;
  };

  const beginRaid = () => {
    if (combinations.length < 1) {
      FunctionStore.handleError("Please select at least one combination");
      return;
    }
    var landFactionInt =
      landFaction == "Zombie"
        ? 1
        : landFaction == "Reborn"
        ? 2
        : landFaction == "Hunter"
        ? 3
        : 0;
    var landTypeInt =
      landType == "Zomb Heavy"
        ? 1
        : landType == "Equal"
        ? 2
        : landType == "NFT Heavy"
        ? 3
        : 0;
    var FinalCombinations = combinations.slice(0, cinput);
    FunctionStore.beginMultiRaids(
      FinalCombinations,
      landFactionInt,
      landTypeInt
    );
  };

  async function autoSelectButton() {
    var Total = 0;
    var currentCombinations = [];
    var combination = autoSelect(currentCombinations);
    while (combination.character) {
      currentCombinations.push(combination);
      combination = autoSelect(currentCombinations);
      if (!combination.character || currentCombinations.length > 50) break;
    }
    setCombinations(currentCombinations);
    if (currentCombinations.length == 0) {
      FunctionStore.handleError("No combinations available");
      return;
    }
  }
  //
  const ImageCard = (asset) => {
    if (asset.video && !AccountStore.getMode()) {
      return (
        <video
          className="viewPopupBoxOptionImage"
          src={`https://atomichub-ipfs.com/ipfs/${asset.video}`}
          autoPlay
          loop
          muted
        />
      );
    } else {
      return (
        <img
          className="viewPopupBoxOptionImage"
          src={
            asset.img
              ? `https://atomichub-ipfs.com/ipfs/${asset.img}`
              : `https://atomichub-ipfs.com/ipfs/${asset.imgback}`
          }
          alt="nft"
        />
      );
    }
  };

  const ViewPopup = () => {
    var allCombinations = combinations.slice(0, cinput);
    console.log(allCombinations);
    return (
      <div className="viewPopup">
        <div className="viewPopupHeader">
          <h4 className="viewPopupTitle">Selected Combinations</h4>
          <button
            className="viewPopupClose"
            onClick={() => {
              setModalOpened(!modalOpened);
            }}
          >
            {" "}
            X{" "}
          </button>
        </div>
        <div className="viewPopupBody">
          {allCombinations.map((combination, index) => {
            return (
              <div className="viewPopupBoxOption" key={index}>
                <div className="viewPopupHeader">
                  <h4 className="viewPopupHeaderText">
                    Combination {index + 1}
                  </h4>
                  <img
                    className="viewPopupCombinationClose"
                    onClick={() => {
                      removeCombination(combination);
                    }}
                  />
                </div>
                <div className="viewPopupBox">
                  <div className="viewPopupBoxOption">
                    <ImageCard {...combination.character} />
                    <h4 className="viewPopupBoxOptionText">
                      {combination.character?.name}
                    </h4>
                    <h4 className="viewPopupBoxOptionText">
                      {combination.character?.power?.total}
                    </h4>
                  </div>
                  {combination.weapon ? (
                    <div className="viewPopupBoxOption">
                      <ImageCard {...combination.weapon} />
                      <ImageCard asset={combination.weapon} />
                      <h4 className="viewPopupBoxOptionText">
                        {combination.weapon?.name}
                      </h4>
                      <h4 className="viewPopupBoxOptionText">
                        {combination.weapon?.power?.total}
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}
                  {combination.commander ? (
                    <div className="viewPopupBoxOption">
                      <ImageCard {...combination.commander} />
                      <h4 className="viewPopupBoxOptionText">
                        {combination.commander?.name}
                      </h4>
                      <h4 className="viewPopupBoxOptionText">
                        {combination.commander?.power?.total}
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}
                  {combination.companion ? (
                    <div className="viewPopupBoxOption">
                      <ImageCard {...combination.companion} />{" "}
                      <h4 className="viewPopupBoxOptionText">
                        {combination.companion?.name}
                      </h4>
                      <h4 className="viewPopupBoxOptionText">
                        {combination.companion?.power?.total}
                      </h4>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="menu">
      <ContentBox>
        {AccountStore.get_loading() ? (
          <>
            <Preloader />
          </>
        ) : (
          <>
            {modalOpened ? <ViewPopup /> : null}
            <div className="multiBox">
              <h4 className="multiBoxTitle">MULTI RAIDS</h4>
              <div className="multiBoxOptions">
                <h4 className="multiBoxOption">Select Faction:</h4>
                {["All", "Zombie", "Reborn", "Hunter"].map((faction, index) => {
                  var selected = factionType === faction;
                  var className = selected
                    ? "selectedOption"
                    : "multiBoxOption";
                  return (
                    <div
                      className={className}
                      onClick={() => setFactionType(faction)}
                    >
                      <img
                        className="multiBoxOptionImage"
                        src={require(`../../assets/toc/${index}.png`)}
                      />
                      <h4 className="multiBoxOptionText">{faction}</h4>
                    </div>
                  );
                })}
              </div>
              <div className="multiBoxOptions">
                <h4 className="multiBoxOption">Land Faction:</h4>
                {["Zombie", "Reborn", "Hunter"].map((faction, index) => {
                  var selected = landFaction === faction;
                  var className = selected
                    ? "selectedOption"
                    : "multiBoxOption";
                  return (
                    <div
                      className={className}
                      onClick={() => setLandFaction(faction)}
                    >
                      <img
                        className="multiBoxOptionImage"
                        src={require(`../../assets/toc/${index + 1}.png`)}
                      />
                      <h4 className="multiBoxOptionText">{faction}</h4>
                    </div>
                  );
                })}
              </div>

              <div className="multiBoxOptions">
                <h4 className="multiBoxOption">Land Type:</h4>
                {["Zomb Heavy", "Equal", "NFT Heavy"].map((land, index) => {
                  var selected = landType === land;
                  var className = selected
                    ? "selectedOption"
                    : "multiBoxOption";
                  return (
                    <div
                      className={className}
                      n
                      onClick={() => setLandtype(land)}
                    >
                      <h4 className="multiBoxOptionText">{land}</h4>
                    </div>
                  );
                })}
              </div>
              <div className="multiBoxOptions">
                <h4 className="multiBoxOption">Total Cost:</h4>
                <div className="multiBoxOption">
                  <h4 className="multiBoxOptionText">{getTotalCost()}</h4>
                  <img
                    className="multiBoxOptionImage"
                    src={require(`../../assets/toc/rads.png`)}
                  />
                </div>
              </div>

              <div className="multiBoxOptions">
                <h4 className="multiBoxOption">Available Combinations:</h4>
                <div className="multiBoxInput">
                  <input
                    className="multiBoxInputField"
                    type="number"
                    placeholder="Enter Combination"
                    value={cinput}
                    min={1}
                    max={getTotalCombinations()}
                    onChange={(e) => {
                      handleInput(e);
                    }}
                  />
                </div>
                <div className="multiBoxInputAvailable">
                  /{" " + getTotalCombinations()}
                </div>
                <div className="multiBoxOption">
                  <img
                    className="multiBoxOptionImage"
                    onClick={() => {
                      setModalOpened(!modalOpened);
                    }}
                  />

                  <h4 className="multiBoxOptionText">View</h4>
                </div>
                <div className="multiBoxOption">
                  <img
                    className="multiBoxOptionImage"
                    onClick={() => {
                      autoSelectButton();
                    }}
                  />
                  <h4 className="multiBoxOptionText">Auto Select</h4>
                </div>
                <img
                  src={require(`../../assets/toc/RAID--16.png`)}
                  className="multiBoxInputImage"
                  alt="tree nft"
                  onClick={(e) => {
                    beginRaid();
                  }}
                />
              </div>
            </div>
          </>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Raid);
