import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import "../Invent/index.sass";
import "./index.sass";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import Preloader from "../../components/preloader";

function Raid_Menu() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [PopupType, setPopupType] = useState("listings");
  const [SchemaType, SetSchemaType] = useState("All");
  const [SortType, SetSortType] = useState("All");
  const [CollType, SetCollType] = useState("All");
  const [currency, SetCurrency] = useState("All");
  const [sellPrice, SetSellPrice] = useState(1);
  const [selectedListing, setSelectedListing] = useState(null);
  const [schemaDefault, setSchemaDefault] = useState([
    { label: "All", value: "All" },
  ]);
  const handleSchemaType = (event) => {
    SetSchemaType(event.target.value);
  };
  const handleCurrencyType = (event) => {
    SetCurrency(event.target.value);
  };
  const handleCollType = (event) => {
    SetCollType(event.target.value);
  };
  const handleSortType = (event) => {
    SetSortType(event.target.value);
  };
  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label className="optionDropDown">
        {label}
        <select value={value} onChange={onChange} className="optionSelect">
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    );
  };

  useEffect(() => {}, []);

  const NFTImage = (object) => {
    var assetObject = object.asset ? object.asset : null;
    var isVideo = assetObject.video ? true : false;
    console.log(assetObject);
    console.log(isVideo);
    var classNameString = object.classString ? object.classString : "";
    return isVideo ? (
      <video
        src={`https://atomichub-ipfs.com/ipfs/${assetObject.video}`}
        className={classNameString}
        alt={require("../../assets/toc/refresh.png")}
        autoPlay
        loop
        muted
      />
    ) : (
      <img
        src={`https://atomichub-ipfs.com/ipfs/${assetObject.img}`}
        className={classNameString}
        alt={require("../../assets/toc/refresh.png")}
      />
    );
  };

  const buyPopup = (listing) => {
    return (
      <div className="buyPopup">
        <div className="buyPopup_title">Confirm Purchase</div>
        <div className="buyPopup__content">
          <div className="buyPopup__content__assets">
            {listing.assets.map((asset, index) => (
              <div className="buyPopup__content__assets__item" key={index}>
                <NFTImage
                  asset={asset}
                  classString="buyPopup__content__assets__item__image"
                />
                <div className="buyPopup__content__assets__item__title">
                  {asset.name}
                </div>
              </div>
            ))}
          </div>
          <div className="buyPopup__content__assets">
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Price</div>
              <div className="buyPopup__content__item__value">
                {listing.price} {listing.currency}
              </div>
            </div>
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Seller</div>
              <div className="buyPopup__content__item__value">
                {listing.seller}
              </div>
            </div>
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Total</div>
              <div className="buyPopup__content__item__value">
                {listing.assets.length + " assets"}
              </div>
            </div>
          </div>
        </div>
        <div className="buyPopup__buttons">
          <button
            className="buyPopup__buttons__cancel"
            onClick={() => setSelectedListing(null) & setPopupType("listings")}
          >
            Cancel
          </button>
          {listing.seller === AccountStore.accountAddress ? (
            <button
              className="buyPopup__buttons__buy"
              onClick={() => FunctionStore.cancelItem(listing.id)}
            >
              Confirm
            </button>
          ) : (
            <button
              className="buyPopup__buttons__buy"
              onClick={() =>
                FunctionStore.buyItem(
                  listing.id,
                  listing.price,
                  listing.currency
                )
              }
            >
              Buy
            </button>
          )}
        </div>
      </div>
    );
  };

  const handlePriceChange = (event) => {
    SetSellPrice(event.target.value);
    console.log(event.target.value);
  };

  const sellPopup = (listing) => {
    var selectedPrice = "1.0000 RADS";
    return (
      <div className="buyPopup">
        <div className="buyPopup_title">Confirm Sell</div>
        <div className="buyPopup__content">
          <div className="buyPopup__content__assets">
            {" "}
            <NFTImage
              asset={listing}
              classString="buyPopup__content__assets__item__image"
            />
          </div>
          <div className="buyPopup__content__assets">
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Price</div>
              <div className="buyPopup__content__item__value">
                Owner: {listing.owner}
              </div>
            </div>
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Price</div>
              <div className="buyPopup__content__item__input">
                <input
                  type="number"
                  className="buyPopup__content__item__input__value"
                  value={sellPrice}
                  onChange={(e) => handlePriceChange(e)}
                />
              </div>
            </div>
            <div className="buyPopup__content__item">
              <div className="buyPopup__content__item__title">Symbol</div>
              <div className="buyPopup__content__item__input">
                <Dropdown
                  label="Token"
                  options={[
                    { label: "RADS", value: "RADS" },
                    { label: "ZOMB", value: "ZOMB" },
                    { label: "WAX", value: "WAX" },
                  ]}
                  value={currency}
                  onChange={handleCurrencyType}
                />
              </div>
            </div>
          </div>
          <div className="buyPopup__content__item">
            <div className="buyPopup__content__item__title">Total Price</div>
            <div className="buyPopup__content__item__input">
              {parseFloat(sellPrice).toFixed(2)} {currency}
            </div>
          </div>
        </div>
        <div className="buyPopup__buttons">
          <button
            className="buyPopup__buttons__cancel"
            onClick={() => setSelectedListing(null) & setPopupType("inventory")}
          >
            Cancel
          </button>

          <button
            className="buyPopup__buttons__buy"
            onClick={() =>
              FunctionStore.sellItem(
                [listing.asset_id],
                parseFloat(sellPrice).toFixed(0),
                currency
              )
            }
          >
            Sell
          </button>
        </div>
      </div>
    );
  };

  const SortOptions = [
    { label: "All", value: "All" },
    { label: "Cheapest", value: "Cheapest" },
    { label: "Most Expensive", value: "Most Expensive" },
    { label: "Newest", value: "Newest" },
    { label: "Oldest", value: "Oldest" },
  ];

  const SchemaOptions = () => {
    var allListings = AccountStore.get_allListings().slice();
    var AllSchema = [{ label: "All", value: "All" }];

    for (var i = 0; i < allListings.length; i++) {
      var listing = allListings[i];
      var symbol = listing.currency;
      var collection_name = listing.assets[0].collection?.collection_name
        ? listing.assets[0].collection.collection_name
        : "cryptodeadnw";
      var schema_name = listing.assets[0].schema?.schema_name
        ? listing.assets[0].schema.schema_name
        : "rocks";
      if (AllSchema.filter((schema) => schema.value === schema_name).length > 0)
        continue;
      AllSchema.push({
        label: schema_name,
        value: schema_name,
      });
    }
    console.log(AllSchema);
    return AllSchema;
  };

  const get_allListings = () => {
    var allListings = AccountStore.get_allListings().slice();

    var allInventory = [];

    for (var i = 0; i < allListings.length; i++) {
      var listing = allListings[i];
      var symbol = listing.currency;
      var collection_name = listing.assets[0].collection?.collection_name
        ? listing.assets[0].collection.collection_name
        : "cryptodeadnw";
      var schema_name = listing.assets[0].schema?.schema_name
        ? listing.assets[0].schema.schema_name
        : "rocks";
      if (SchemaType !== "All" && schema_name !== SchemaType) continue;
      if (CollType !== "All" && CollType !== collection_name) continue;
      if (currency !== "All" && currency !== symbol) continue;
      if (SortType !== "All") {
        if (SortType === "Cheapest") {
          allInventory = allInventory.sort((a, b) => {
            return parseInt(a.price) - parseFloat(b.price);
          });
        } else if (SortType === "Most Expensive") {
          allInventory = allInventory.sort((a, b) => {
            return parseFloat(b.price) - parseFloat(a.price);
          });
        } else if (SortType === "Newest") {
          allInventory = allInventory.sort((a, b) => {
            return parseFloat(a.id) - parseFloat(b.id);
          });
        } else if (SortType === "Oldest") {
          allInventory = allInventory.sort((a, b) => {
            return parseFloat(b.id) - parseFloat(a.id);
          });
        }
      }
      allInventory.push(listing);
    }
    return allInventory;
  };

  const get_allMyListings = () => {
    var allListings = AccountStore.get_allListings().slice();
    allListings = allListings.filter((listing) => {
      return listing.seller === AccountStore.accountAddress;
    });

    var getAllSchema = [];

    var allInventory = [];

    for (var i = 0; i < allListings.length; i++) {
      var listing = allListings[i];
      var symbol = listing.currency;
      var collection_name = listing.assets[0].collection?.collection_name
        ? listing.assets[0].collection.collection_name
        : "cryptodeadnw";
      var schema_name = listing.assets[0].schema?.schema_name
        ? listing.assets[0].schema.schema_name
        : "rocks";

      if (SchemaType !== "All" && schema_name !== SchemaType) continue;
      if (currency !== "All" && currency !== symbol) continue;

      allInventory.push(listing);
    }
    if (SortType !== "All") {
      if (SortType === "Cheapest") {
        allInventory = allInventory.sort((a, b) => {
          return parseInt(a.price) - parseFloat(b.price);
        });
      } else if (SortType === "Most Expensive") {
        allInventory = allInventory.sort((a, b) => {
          return parseFloat(b.price) - parseFloat(a.price);
        });
      } else if (SortType === "Newest") {
        allInventory = allInventory.sort((a, b) => {
          return parseFloat(a.id) - parseFloat(b.id);
        });
      } else if (SortType === "Oldest") {
        allInventory = allInventory.sort((a, b) => {
          return parseFloat(b.id) - parseFloat(a.id);
        });
      }
    }
    return allInventory;
  };

  const MyListings = () => {
    return (
      <>
        <div className="marketplace__tabs">
          <Dropdown
            label="Sort"
            options={SortOptions}
            value={SortType}
            onChange={handleSortType}
          />
          <Dropdown
            label="Schema"
            options={schemaDefault}
            value={SchemaType}
            onChange={handleSchemaType}
          />
          <Dropdown
            label="Token"
            options={[
              { label: "All", value: "All" },
              { label: "RADS", value: "RADS" },
              { label: "ZOMB", value: "ZOMB" },
              { label: "WAX", value: "WAX" },
            ]}
            value={currency}
            onChange={handleCurrencyType}
          />
        </div>
        <div className="marketplace__content">
          {get_allMyListings().map((listing, index) => {
            return (
              <div className="marketplace__item">
                <div className="marketplace__item__title">
                  {listing.assets.length > 1
                    ? listing.assets.length + " assets"
                    : listing.assets?.[0].name}
                </div>
                <NFTImage
                  asset={listing.assets[0]}
                  classString="marketplace__item__image"
                />
                <div className="marketplace__item__title">
                  Owner: {listing.seller}
                </div>
                <button
                  className="marketplace__item__button"
                  onClick={() => {
                    setPopupType("cancel");
                    setSelectedListing(listing);
                  }}
                >
                  Cancel
                </button>
                <div className="marketplace__item__title">
                  {listing.price} {listing.currency}
                  <img
                    src={require(`../../assets/toc/${listing.currency
                      .toString()
                      .toLowerCase()
                      .replace(" ", "")}.png`)}
                    alt="currency"
                    className="marketplace__item__title__currency"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const Listings = () => {
    return (
      <>
        <div className="marketplace__tabs">
          <Dropdown
            label="Sort"
            options={SortOptions}
            value={SortType}
            onChange={handleSortType}
          />
          <Dropdown
            label="Collection"
            options={[
              { label: "All", value: "All" },
              { label: "CryptoDeadNW", value: "cryptodeadnw" },
              { label: "TalesOfCrypt", value: "talesofcrypt" },
            ]}
            value={CollType}
            onChange={handleCollType}
          />
          <Dropdown
            label="Schema"
            options={SchemaOptions()}
            value={SchemaType}
            onChange={handleSchemaType}
          />
          <Dropdown
            label="Token"
            options={[
              { label: "All", value: "All" },
              { label: "RADS", value: "RADS" },
              { label: "ZOMB", value: "ZOMB" },
              { label: "WAX", value: "WAX" },
            ]}
            value={currency}
            onChange={handleCurrencyType}
          />
        </div>
        <div className="marketplace__content">
          {get_allListings().map((listing, index) => {
            return (
              <div className="marketplace__item">
                <div className="marketplace__item__title">
                  {listing.assets.length > 1
                    ? listing.assets.length + " assets"
                    : listing.assets?.[0].name}
                </div>
                <NFTImage
                  asset={listing.assets[0]}
                  classString="marketplace__item__image"
                />
                <div className="marketplace__item__title">
                  Owner: {listing.seller}
                </div>
                <button
                  className="marketplace__item__button"
                  onClick={() => {
                    setPopupType("buy");
                    setSelectedListing(listing);
                  }}
                >
                  Buy
                </button>
                <div className="marketplace__item__title">
                  {listing.price} {listing.currency}
                  <img
                    src={require(`../../assets/toc/${listing.currency
                      .toString()
                      .toLowerCase()
                      .replace(" ", "")}.png`)}
                    alt="currency"
                    className="marketplace__item__title__currency"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const get_allInventory = () => {
    var allInvent = AccountStore.stakingAssets.slice();
    var allUserAssets = AccountStore.userAssets.commanders.concat(
      AccountStore.userAssets.weapons.concat(
        AccountStore.userAssets.characters.concat(
          AccountStore.userAssets.companions.concat(
            AccountStore.userAssets.lands
          )
        )
      )
    );
    allInvent = allInvent.concat(allUserAssets);
    var allInventory = [];
    for (var i = 0; i < allInvent.length; i++) {
      var asset = allInvent[i];
      var collection_name = asset.collection?.collection_name
        ? asset.collection.collection_name
        : "cryptodeadnw";
      var schema_name = asset.schema?.schema_name
        ? asset.schema.schema_name
        : "rocks";
      if (SchemaType !== "All" && schema_name !== SchemaType) continue;
      if (CollType !== "All" && CollType !== collection_name) continue;
      if (currency !== "All" && currency !== asset.rarity) continue;

      asset.video = asset.video
        ? asset.video
        : asset.template?.immutable_data.video
        ? asset.template?.immutable_data.video
        : null;
      asset.img = asset.img
        ? asset.img
        : asset.template?.immutable_data.img
        ? asset.template?.immutable_data.img
        : null;
      allInventory.push(asset);
    }
    console.log(allInventory);
    return allInventory;
  };

  const Inventory = () => {
    return (
      <>
        <div className="marketplace__tabs">
          <Dropdown
            label="Collection"
            options={[
              { label: "All", value: "All" },
              { label: "CryptoDeadNW", value: "cryptodeadnw" },
              { label: "TalesOfCrypt", value: "talesofcrypt" },
            ]}
            value={CollType}
            onChange={handleCollType}
          />
          <Dropdown
            label="Schema"
            options={schemaDefault}
            value={SchemaType}
            onChange={handleSchemaType}
          />
          <Dropdown
            label="Rarity"
            options={[
              { label: "All", value: "All" },
              { label: "Common", value: "Common" },
              { label: "Uncommon", value: "Uncommon" },
              { label: "Rare", value: "Rare" },
              { label: "Epic", value: "Epic" },
              { label: "Legendary", value: "Legendary" },
              { label: "Mythic", value: "Mythic" },
            ]}
            value={currency}
            onChange={handleCurrencyType}
          />
        </div>
        <div className="marketplace__content">
          {get_allInventory().map((listing, index) => {
            return (
              <div className="marketplace__item">
                <div className="marketplace__item__title">
                  {listing.asset_id}
                </div>
                <NFTImage
                  asset={listing}
                  classString="marketplace__item__image"
                />
                <div className="marketplace__item__title">{listing.rarity}</div>
                <button
                  className="marketplace__item__button"
                  onClick={() => {
                    setPopupType("sell");
                    SetCurrency("RADS");
                    setSelectedListing(listing);
                  }}
                >
                  Sell
                </button>
                <div className="marketplace__item__title">{listing.name}</div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <ContentBox title="MarketPlace">
      {AccountStore.get_loading() ? (
        <Preloader />
      ) : (
        <>
          <div className="marketplace">
            <div className="marketplace_bg" />
            <div className="marketplace__tabs">
              <div
                className={
                  PopupType === "listings"
                    ? "marketplace__tab-active"
                    : "marketplace__tab"
                }
                onClick={() => {
                  setPopupType("listings");
                }}
              >
                Marketplace
              </div>
              <div
                className={
                  PopupType === "inventory"
                    ? "marketplace__tab-active"
                    : "marketplace__tab"
                }
                onClick={() => {
                  setPopupType("inventory");
                  SetCurrency("All");
                }}
              >
                Inventory
              </div>
              <div
                className={
                  PopupType === "mylistings"
                    ? "marketplace__tab-active"
                    : "marketplace__tab"
                }
                onClick={() => {
                  setPopupType("mylistings");
                  SetCurrency("All");
                }}
              >
                Listings
              </div>
            </div>
            {PopupType === "listings" ? (
              <Listings />
            ) : PopupType === "inventory" ? (
              <Inventory />
            ) : PopupType === "mylistings" ? (
              <MyListings />
            ) : PopupType === "buy" ? (
              selectedListing && buyPopup(selectedListing)
            ) : PopupType === "cancel" ? (
              selectedListing && buyPopup(selectedListing)
            ) : (
              PopupType === "sell" &&
              selectedListing &&
              sellPopup(selectedListing)
            )}
          </div>
        </>
      )}
    </ContentBox>
  );
}

export default observer(Raid_Menu);
