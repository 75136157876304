import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import HeaderContainer from "../../containers/headerContainer";
import "../Invent/index.sass";
import "./index.sass";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";
import { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import MyTimer from "../../components/timer";
import Preloader from "../../components/preloader";

function Raid_Menu() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [PopupType, setPopupType] = useState("none");

  function nftCard(asset) {
    if (asset)
      return asset.video && !AccountStore.getMode() ? (
        <video
          src={`https://atomichub-ipfs.com/ipfs/${asset.video}`}
          className={`assets-slider-item-image`}
          alt={`${asset.name}`}
          autoPlay
          muted
          loop
        />
      ) : (
        <img
          src={
            asset.img
              ? `https://atomichub-ipfs.com/ipfs/${asset.img}`
              : `https://atomichub-ipfs.com/ipfs/${asset.imgback}`
          }
          className={`assets-slider-item-image2  `}
          alt={`${asset.name} ${asset.asset_id}`}
        />
      );
    else return <></>;
  }

  function get_rtime(endtime) {
    var current = new Date().getTime() / 1000;
    var tr = endtime - current;
    var time = new Date().getTime() + tr * 1000;
    if (tr <= 0) time = 0;
    var finalz = parseInt(time);
    return finalz;
  }

  const getConfigData = (id) => {
    var configData = FunctionStore.getConfigData(id);
    if (configData) {
      console.log(configData);
      return (
        configData.player_faction +
        " VS " +
        configData.land_faction +
        " , " +
        configData.land_type
      );
    } else {
      return "loading";
    }
  };

  return (
    <div className="menu">
      <ContentBox title="Raid Menu" subtitle="Select Option">
        {AccountStore.get_loading() ? (
          <Preloader />
        ) : (
          <>
            {PopupType === "none" ? (
              <div className="menu-options">
                <div
                  className="menu-option"
                  onClick={() => {
                    AccountStore.setSelectedAsset(
                      "radrocks",
                      "radrocks",
                      "Inventory"
                    );
                    AccountStore.setStage("rocks");
                  }}
                >
                  <img
                    src={require("../../assets/toc/CLAIMRADROCKS.png")}
                    alt="token exchange"
                    className="menu-option-image"
                  />
                  <div className="menu-option-description">Claim Radrocks</div>
                </div>
                <div
                  className="menu-option"
                  onClick={() => {
                    setPopupType("Raids");
                  }}
                >
                  <img
                    src={require("../../assets/toc/ACTIVERAIDS.png")}
                    alt="trees"
                    className="menu-option-image"
                  />
                  <div className="menu-option-description">
                    View Active Raids
                  </div>
                </div>
                <div
                  className="menu-option"
                  onClick={() => {
                    AccountStore.setStage("MultiRaid");
                  }}
                >
                  {" "}
                  <img
                    src={require("../../assets/toc/NEWRAID.png")}
                    alt=""
                    className="menu-option-image"
                  />
                  <div className="menu-option-description">{"🧪"}</div>
                  <div className="menu-option-description">Multi Raids</div>
                </div>

                <div
                  className="menu-option"
                  onClick={() => {
                    AccountStore.setStage("raid");
                    AccountStore.setRaidAsset("all", {
                      lands: {
                        schema: "lands",
                      },
                      characters: {
                        schema: "characters",
                      },
                      companions: {
                        schema: "companions",
                      },
                      commanders: {
                        schema: "commanders",
                      },
                      weapons: {
                        schema: "weapons",
                      },
                      type: 1,
                      faction: 1,
                    });
                  }}
                >
                  <img
                    src={require("../../assets/toc/NEWRAID.png")}
                    alt=""
                    className="menu-option-image"
                  />
                  <div className="menu-option-description">New Raid</div>
                </div>
              </div>
            ) : (
              <div className="asset-inventory-content">
                <div className="asset-inventory-content-buttons">
                  <button
                    className="asset-inventory-content-button"
                    onClick={() => {
                      FunctionStore.claimRaid(
                        AccountStore.getRaids()
                          .filter((asset) => get_rtime(asset.end_time) === 0)
                          .map((asset, index) => {
                            return asset.id;
                          })
                      );
                    }}
                  >
                    Claim All{" <"}
                    {
                      AccountStore.getRaids().filter((asset) => {
                        return get_rtime(asset.end_time) === 0;
                      }).length
                    }
                    {"> "}
                  </button>
                </div>

                <div className="asset-inventory-content-slider">
                  <AssetsSlider>
                    {AccountStore.getRaids().map((asset, index) => {
                      return (
                        <SwiperSlide key={asset.id}>
                          <div
                            className="assets-slider-item wallet-slider-item"
                            key={index}
                          >
                            <h2 className="assets-slider-item-sub">
                              {asset.id}
                            </h2>
                            <div className="assets-slider-item-image-wrapper">
                              {asset.characters ? (
                                nftCard(asset.characters)
                              ) : (
                                <></>
                              )}
                              {asset.companions ? (
                                nftCard(asset.companions)
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="assets-slider-item-image-wrapper">
                              {asset.weapons ? nftCard(asset.weapons) : <></>}
                              {asset.commanders ? (
                                nftCard(asset.commanders)
                              ) : (
                                <></>
                              )}
                            </div>

                            <div className="assets-slider-item-buttons">
                              <div className="assets-slider-item-buttons">
                                <h4 className="assets-slider-item-sub">
                                  {getConfigData(asset.config_id)}
                                </h4>
                                <h4 className="assets-slider-item-sub">
                                  {FunctionStore.get_asset_boost(asset)}
                                </h4>
                              </div>
                              {get_rtime(asset.end_time) === 0 ? (
                                <>
                                  <h4
                                    className="assets-slider-item-sub"
                                    style={{ marginTop: "10px" }}
                                  >
                                    {FunctionStore.get_reward(asset)}
                                  </h4>
                                  <button
                                    className="assets-slider-item-button"
                                    onClick={() => {
                                      FunctionStore.claimRaid([asset.id]);
                                    }}
                                  >
                                    Claim
                                  </button>{" "}
                                </>
                              ) : (
                                <>
                                  <MyTimer
                                    expiryTimestamp={get_rtime(asset.end_time)}
                                    asset={asset}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </AssetsSlider>
                </div>
              </div>
            )}
          </>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Raid_Menu);
