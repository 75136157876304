import { useTimer } from "react-timer-hook";
import StoreContext from "../../store/RootStore";
import "../../pages/Invent/index.sass";

function MyTimer({ expiryTimestamp, asset }) {
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: () => console.warn("onExpire called"),
  });

  const { AccountStore, FunctionStore } = StoreContext();
  function get_state() {
    return seconds === 0 && hours === 0 && minutes === 0 ? (
      <>
        <h4 className="assets-slider-item-sub">
          {FunctionStore.get_asset_boost(asset)}
          {FunctionStore.get_Conf(asset)}
          <br />
          {FunctionStore.get_reward(asset)}
        </h4>
        <button
          className="assets-slider-item-button"
          onClick={() => {
            FunctionStore.claimRaid([asset.id]);
          }}
        >
          Claim
        </button>
      </>
    ) : (
      <div style={{ fontSize: "1vw", color: "white" }}>
        ENDS IN: <span>{hours}</span>:<span>{minutes}</span>:
        <span>{seconds}</span>
      </div>
    );
  }
  return <div style={{ textAlign: "center" }}>{get_state()}</div>;
}

export default MyTimer;

//0.6498
