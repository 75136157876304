import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import { useEffect, useState } from "react";
import "./index.sass";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";

function Leaderboard() {
  const { AccountStore, FunctionStore } = StoreContext();
  var [leaderboard, setLeaderboard] = useState([]);
  var [Wleaderboard, setWLeaderboard] = useState([]);
  var [tab, setTab] = useState("global");

  useEffect(() => {
    getLeaderboard();
  }, []);

  async function getLeaderboard() {
    var gleaderboard = await FunctionStore.getLeaderboard("cdnwraidgame");
    var weeklyLeaderboard = await FunctionStore.getLeaderboard("weekly");
    if (gleaderboard.length > 0) setLeaderboard(gleaderboard);
    if (weeklyLeaderboard.length > 0) setWLeaderboard(weeklyLeaderboard);
    console.log(weeklyLeaderboard);
    console.log(gleaderboard);
  }

  const getLData = () => {
    var finalLeaderboard = [];
    if (tab === "global") {
      finalLeaderboard = leaderboard;
    } else {
      finalLeaderboard = Wleaderboard;
    }
    if (finalLeaderboard.length > 0)
      finalLeaderboard = finalLeaderboard.sort((a, b) => {
        return b.raids - a.raids;
      });
    return finalLeaderboard;
  };

  return (
    <div className="shop-page">
      <ContentBox title="Leaderboard">
        <div className="leaderboard-tabs">
          <div
            className={`leaderboard-tab ${
              tab === "global" ? "leaderboard-tab-active" : ""
            }`}
            onClick={() => setTab("global")}
          >
            Global
          </div>
          <div
            className={`leaderboard-tab ${
              tab === "weekly" ? "leaderboard-tab-active" : ""
            }`}
            onClick={() => setTab("weekly")}
          >
            Weekly
          </div>
        </div>
        <div className="leaderboard-header">
          <div className="leaderboard-header-text">Rank</div>
          <div className="leaderboard-header-text">Name</div>
          <div className="leaderboard-header-text">Total Raids</div>
          <div className="leaderboard-header-text">RADS spent</div>
          <div className="leaderboard-header-text">ZOMB earned</div>
          <div className="leaderboard-header-text">NFTs won</div>
        </div>
        <div className="leaderboard">
          {getLData().map((player, index) => {
            return (
              <div className="leaderboard-player" key={index}>
                <div className="leaderboard-player-text">{index + 1}</div>
                <div className="leaderboard-player-text">{player.player}</div>
                <div className="leaderboard-player-text">{player.raids}</div>
                <div className="leaderboard-player-text">
                  {parseFloat(player.rads / 10000).toFixed(4)}
                </div>
                <div className="leaderboard-player-text">
                  {parseFloat(player.zomb / 10000).toFixed(4)}
                </div>
                <div className="leaderboard-player-text">{player.nfts}</div>
              </div>
            );
          })}
        </div>
      </ContentBox>
    </div>
  );
}

export default observer(Leaderboard);
