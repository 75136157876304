import "./index.sass";
import "./burger-styles.sass";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";

function Header() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const { pathname } = useLocation();

  // handle state callback for the sidebar component
  function handleStateChange(state) {
    setSidebarOpened(state.isOpen);
  }

  return (
    <nav className="topheader">
      <div className="logo" />
      <div className="bheader">
        <NavLink
          to="/"
          className={({ isActive }) =>
            isActive ? "header-link header-link-active" : "header-link"
          }
        >
          HOME
        </NavLink>
        <a
          href="https://www.talesofthecrypto.co/assets/zombpaper_081022.pdf"
          target="_blank"
          rel="noreferrer"
          onClick={() => setSidebarOpened(false)}
          className="header-link"
        >
          Whitepaper
        </a>
      </div>
    </nav>
  );
}

export default observer(Header);

//0.6498
