import React, { useState, useEffect } from "react";

function STimer({ endTime }) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeRemaining = getTimeRemaining(endTime);
      setTotal(timeRemaining.total);
      setDays(timeRemaining.days);
      setHours(timeRemaining.hours);
      setMinutes(timeRemaining.minutes);
      setSeconds(timeRemaining.seconds);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [endTime]);

  function getTimeRemaining(endTime) {
    var total = Date.parse(endTime) - Date.parse(new Date());
    if (total < 0) total = 0;
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));
    return { total, days, hours, minutes, seconds };
  }
  return (
    <div
      style={{
        fontSize: "1.5vw",
        color: "white",
        textAlign: "center",
      }}
    >
      {(hours === 0 && minutes === 0 && seconds === 0) || total <= 0
        ? "READY "
        : !hours || !minutes || !seconds
        ? "Loading..."
        : hours + " H: " + minutes + " M: " + seconds + " S"}
    </div>
  );
}

export default STimer;
