import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import Login from "../../pages/Login";
import Menu from "../../pages/Menu";
import RaidMenu from "../../pages/Raid_Menu";
import Raid from "../../pages/Raid";
import Statistics from "../../pages/Statistics";
import Rocks from "../../pages/Rocks";
import HeaderContainer from "../../containers/headerContainer";
import "./index.sass";
import Invent from "../../pages/Invent";
import TokenExchange from "../../pages/TokenExchange";
import Shop from "../../pages/Shop";
import Leaderboard from "../../pages/Leaderboard";
import Scavenge from "../../pages/Scavenge";
import Staking from "../../pages/Staking";
import MarketPlace from "../../pages/MarketPlace";
import MultiRaid from "../../pages/MultiRaid";

function GameRouter() {
  const { AccountStore } = StoreContext();

  function renderGameScreen() {
    switch (AccountStore.getStage()) {
      case "login":
        return (
          <HeaderContainer>
            <Login />
          </HeaderContainer>
        );

      case "marketplace":
        return (
          <HeaderContainer>
            <MarketPlace />
          </HeaderContainer>
        );

      case "MultiRaid":
        return (
          <HeaderContainer>
            <MultiRaid />
          </HeaderContainer>
        );

      case "menu":
        return (
          <HeaderContainer>
            <Menu />
          </HeaderContainer>
        );

      case "raid":
        return (
          <HeaderContainer>
            <Raid />
          </HeaderContainer>
        );
      case "raid_menu":
        return (
          <HeaderContainer>
            <RaidMenu />
          </HeaderContainer>
        );

      case "statistics":
        return (
          <HeaderContainer>
            <Statistics />
          </HeaderContainer>
        );

      case "rocks":
        return (
          <HeaderContainer>
            <Rocks />
          </HeaderContainer>
        );

      case "inventory":
        return (
          <HeaderContainer>
            <Invent />
          </HeaderContainer>
        );

      case "token_exchange":
        return (
          <HeaderContainer>
            <TokenExchange />
          </HeaderContainer>
        );

      case "shop":
        return (
          <HeaderContainer>
            <Shop />
          </HeaderContainer>
        );

      case "leaderboard":
        return (
          <HeaderContainer>
            <Leaderboard />
          </HeaderContainer>
        );

      case "scavenge":
        return (
          <HeaderContainer>
            <Scavenge />
          </HeaderContainer>
        );

      case "staking":
        return (
          <HeaderContainer>
            <Staking />
          </HeaderContainer>
        );

      default:
        return;
    }
  }

  return <>{renderGameScreen()}</>;
}

export default observer(GameRouter);
