import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppRoutes from "./routes";
import "./index.sass";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <AppRoutes />
    <App />
  </React.StrictMode>
);
