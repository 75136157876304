import "./index.sass"
import Header from "../../components/header";
import StoreContext from "../../store/RootStore";

export default function HeaderContainer({children}) {
    const {AccountStore} = StoreContext();
    // debugger;
    return (
        <main className="main-content">
            <Header/>
            <div className="content-wrapper">
                {children}
            </div>
        </main>
    )
}