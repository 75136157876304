import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import "../Raid_Menu/index.sass";
import Preloader from "../../components/preloader";
import STimer from "../../components/stimer";

function Menu() {
  const { AccountStore, FunctionStore } = StoreContext();
  function selectStaking() {
    if (AccountStore.stakingAssets.length === 0) FunctionStore.getAllCollNFTs();
    AccountStore.setStage("staking");
  }

  async function openMarket() {
    await FunctionStore.getAllListings();
    AccountStore.setStage("marketplace");
  }
  return (
    <div className="menu">
      <ContentBox title="Main Menu" subtitle="Select an option">
        {AccountStore.loading ? (
          <Preloader />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
                height: "100%",
              }}
            >
              <div className="menu-options">
                <div
                  className="menu-option"
                  onClick={() => AccountStore.setStage("raid_menu")}
                >
                  <img
                    src={require("../../assets/toc/RAIDLOGO.png")}
                    alt="trees"
                    className="menu-option-image"
                  />
                </div>
                <div
                  className="menu-option"
                  onClick={() => AccountStore.setStage("inventory")}
                >
                  <img
                    src={require("../../assets/toc/inventory.png")}
                    alt="inventory"
                    className="menu-option-image"
                  />
                </div>
                <div
                  className="menu-option"
                  onClick={() => AccountStore.setStage("shop")}
                >
                  <img
                    src={require("../../assets/toc/shop.png")}
                    alt="token exchange"
                    className="menu-option-image"
                  />
                </div>

                <div
                  className="menu-option"
                  onClick={() => AccountStore.setStage("rads_exchange")}
                >
                  <img
                    src={require("../../assets/toc/EXCHANGERADS.png")}
                    alt="token exchange"
                    className="menu-option-image"
                  />
                </div>
                <div
                  className="menu-option"
                  onClick={() => AccountStore.setStage("zomb_exchange")}
                >
                  <img
                    src={require("../../assets/toc/EXCHANGEZOMB.png")}
                    alt="token exchange"
                    className="menu-option-image"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  marginBottom: "25px",
                  marginLeft: "10px",
                  gap: "10px",
                  padding: "10px",
                  flexWrap: "wrap",
                  fontSize: "2vh",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.8)",
                    border: "1px solid #000",
                    borderRadius: "10px",
                    alignItems: "center",
                    border: "1px solid white",
                    cursor: "pointer",
                    padding: "5vh",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    maxHeight: "10vh",
                  }}
                  onClick={() => selectStaking()}
                >
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                    }}
                  >
                    {"Staking 💸"}{" "}
                  </h2>
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                      color: "red",
                    }}
                  >
                    {"RADS/DAY:" +
                      AccountStore.stakingAssets
                        .filter((a) => a.staked)
                        .reduce((a, b) => a + parseFloat(b.rate), 0)
                        .toFixed(0)}{" "}
                  </h2>
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                      color: "yellow ",
                    }}
                  >
                    {"WUF/DAY:" +
                      AccountStore.wufMiners
                        .filter((a) => a.staked && a.config)
                        .reduce((a, b) => a + parseFloat(b.rate), 0)
                        .toFixed(0)}{" "}
                  </h2>
                </div>{" "}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.8)",
                    border: "1px solid #000",
                    alignItems: "center",
                    borderRadius: "10px",
                    padding: "5vh",
                    maxHeight: "10vh",
                    border: "1px solid white",
                    cursor: "pointer",
                    height: "100%",
                  }}
                  onClick={() => openMarket()}
                >
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                    }}
                  >
                    {"Market 🏪"}{" "}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(0, 0, 0, 0.8)",
                    border: "1px solid #000",
                    maxHeight: "10vh",
                    borderRadius: "10px",
                    padding: "5vh",
                    border: "1px solid white",
                    cursor: "pointer",
                    height: "100%",
                  }}
                  onClick={() => AccountStore.setStage("scavenge")}
                >
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                    }}
                  >
                    {"Scavenge 🧟"}{" "}
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "rgba(0, 0, 0, 0.8)",
                    maxHeight: "10vh",
                    alignItems: "center",
                    border: "1px solid #000",
                    borderRadius: "10px",
                    padding: "5vh",
                    border: "1px solid white",
                    cursor: "pointer",
                    height: "100%",
                  }}
                  onClick={() => AccountStore.setStage("leaderboard")}
                >
                  <h2
                    style={{
                      color: "white",
                      fontFamily: "game-over",
                    }}
                  >
                    {"Rankings 🏆"}{" "}
                  </h2>
                </div>
              </div>
            </div>
          </>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Menu);
