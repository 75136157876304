import "./index.sass";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-button.svg";
import { ReactComponent as GoBackArrow } from "../../assets/icons/go-back-arrow.svg";
import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import { useEffect, useState, useContext } from "react";

function ContentBox({ title, subtitle, children }) {
  const { AccountStore, FunctionStore } = StoreContext();
  const [WaxType, SetWaxType] = useState(false);
  const [utilityType, SetutilityType] = useState(false);
  const [DropdownActive, setDropdownActive] = useState(false);

  const logout = () => {
    FunctionStore.logOut();
    AccountStore.clearAllData();
  };

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label className="optionDropDown">
        {label}
        <select value={value} onChange={onChange} className="optionSelect">
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    );
  };

  const handleWaxType = (event) => {
    AccountStore.set_endpoint(event.target.value);
    SetWaxType(event.target.value);
  };

  const handleAtomicType = (event) => {
    AccountStore.set_aaendpoint(event.target.value);
    SetutilityType(event.target.value);
  };

  const setDropDown = () => {
    setDropdownActive(!DropdownActive);
  };

  return (
    <div className={`content-box`}>
      <header className="header">
        {DropdownActive ? (
          <div className="header-info">
            <div className="header-info-item">
              <button
                className="header-info-item-button"
                onClick={() => {
                  setDropDown();
                }}
              >
                <span className="header-info-item-text">RPC {">"}</span>
              </button>
            </div>
            <Dropdown
              label="WAX ENDPOINT"
              options={AccountStore.getWaxEndpointList()}
              value={WaxType}
              onChange={handleWaxType}
            />
            <Dropdown
              label="ATOMIC ENDPOINT"
              options={AccountStore.getAAEndpointList()}
              value={utilityType}
              onChange={handleAtomicType}
            />
          </div>
        ) : (
          <div className="header-info">
            <div className="header-info-item">
              <button
                className="header-info-item-button"
                onClick={() => {
                  setDropDown();
                }}
              >
                <span className="header-info-item-text">RPC {">"}</span>
              </button>
            </div>
            <button
              className="header-log-out"
              onClick={() => {
                AccountStore.get_loading() ? <></> : logout();
              }}
            ></button>
            <div className="header-info-item">
              <span className="header-info-item-text">
                {AccountStore.accountAddress}
              </span>
            </div>

            <button
              className="header-refresh"
              onClick={() => {
                FunctionStore.reloadGame();
              }}
            ></button>

            <button
              onClick={() => {
                FunctionStore.SwitchMode();
              }}
              style={{
                backgroundColor: "transparent",
                border: "white 1px solid",
                outline: "none",
                width: "4vw",
                height: "6vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              {AccountStore.getMode() ? (
                //add an icon for mobile from internet
                <img
                  src={require("../../assets/icons/mobile.png")}
                  style={{
                    width: "2vw",
                    height: "5vh",
                    backgroundColor: "transparent",
                  }}
                />
              ) : (
                <img
                  src={require("../../assets/icons/desktop.png")}
                  style={{
                    width: "3vw",
                    height: "5vh",
                    backgroundColor: "transparent",
                  }}
                />
              )}
            </button>

            <div className="header-info-item">
              <h6 className="header-info-item-text">
                {AccountStore.getBalancewallet("game", "RADS")}
              </h6>
              <img
                src={require("../../assets/toc/rads.png")}
                alt="RADS Token"
                className="header-info-item-image"
              />
            </div>
            <div className="header-info-item">
              <h6 className="header-info-item-text">
                {AccountStore.getBalancewallet("game", "ZOMB")}
              </h6>
              <img
                src={require("../../assets/toc/zomb.png")}
                alt="RADS Token"
                className="header-info-item-image"
              />
            </div>
            <div className="header-info-item">
              <h6 className="header-info-item-text">
                {AccountStore.getRaidCounts()}/ {AccountStore.maxRaids}
              </h6>
              <img
                src={require("../../assets/toc/RAIDLOGO.png")}
                alt="RADS Token"
                className="header-info-item-image"
              />
            </div>
            {AccountStore.getStage() !== "menu" &&
            AccountStore.getStage() !== "login" ? (
              <img
                className="content-box-nav-button"
                alt=""
                src={require(`../../assets/toc/arrows.png`)}
                onClick={() => {
                  AccountStore.setStage("menu");
                }}
              />
            ) : (
              <></>
            )}
            {AccountStore.getStage() === "login" && AccountStore.zoomed ? (
              <img
                className="content-box-nav-button"
                alt=""
                src={require(`../../assets/toc/BACKBUTTON.png`)}
                onClick={() => {
                  AccountStore.set_zoomed(false);
                }}
              />
            ) : (
              <></>
            )}
            {AccountStore.getStage() !== "menu" &&
            AccountStore.getStage() !== "login" &&
            AccountStore.getStageHistory().length > 1 ? (
              <img
                className="content-box-nav-button"
                alt=""
                src={require(`../../assets/toc/BACKBUTTON.png`)}
                onClick={() => {
                  AccountStore.setPreviousStage(
                    AccountStore.getStageHistory(
                      AccountStore.getStageHistory().length - 1
                    )
                  );
                }}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </header>
      {title ? <h1 className="content-box-title">{title}</h1> : ""}
      {subtitle ? <h4 className="content-box-subtitle">{subtitle}</h4> : ""}
      <div className="content-box-content">{children}</div>
    </div>
  );
}

export default observer(ContentBox);
