import { makeAutoObservable, toJS } from "mobx";
import AccountStore from "./AccountStore";
import * as waxjs from "@waxio/waxjs/dist";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import { toast } from "react-toastify";
import { Chains, Session, SessionKit } from "@wharfkit/session";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";
import WebRenderer from "@wharfkit/web-renderer";
import { TransactPluginResourceProvider } from "@wharfkit/transact-plugin-resource-provider";

class FunctionStore {
  constructor() {
    makeAutoObservable(this);
  }
  sessionKit = new SessionKit(
    {
      appName: "TOTC",
      chains: [Chains.WAX],
      ui: new WebRenderer(),
      walletPlugins: [
        new WalletPluginAnchor(),
        new WalletPluginCloudWallet(),
        new WalletPluginWombat(),
      ],
    },
    {
      transactPlugins: [new TransactPluginResourceProvider()],
    }
  );
  activeSession = Session || null;
  //
  endpoint = "https://wax.api.eosnation.io";
  atomic_api = "https://wax-aa.eosdac.io";
  transport = new AnchorLinkBrowserTransport();
  anchorLink = new AnchorLink({
    transport: this.transport,
    chains: [
      {
        chainId:
          "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
        nodeUrl: this.endpoint,
      },
    ],
  });
  dapp = "cdnwraidgame";
  contract = "cdnwraidgame";
  rockcontract = "cdnwnftpools";
  tokenContract = "radshroomers";
  tokenContract2 = "zombietokens";
  marketContract = "cdzmarketotc";
  collectionName = "cryptodeadnw";
  collectionName2 = "talesofcrypt";
  character_schema = "characters";
  weapon_schema = "weapons";
  allTemplates = [];
  path = "/v1/chain/get_table_rows";
  eosjsName = require("eosjs-account-name");

  handleError(e) {
    AccountStore.set_loading(false);
    console.log(e);
    toast.warning(
      e.message
        ? e.message.includes("undefined")
          ? "Failed to fetch!"
          : e.message
        : e,
      { autoClose: 10000 }
    );
    //console.error(e);
  }

  set_endpoint(params) {
    //console.log(params);
    this.endpoint = params;
  }
  set_aaendpoint(params) {
    //console.log(params);
    this.atomic_api = params;
  }
  handleSuccess(message) {
    toast.success(message);
    //console.log("Success! ", message)
  }

  async wharfLogin() {
    try {
      var sessionLogin = null;
      if (await this.sessionKit.restore())
        sessionLogin = await this.sessionKit.restore();
      else {
        var response = await this.sessionKit.login();
        sessionLogin = response.session;
      }
      if (sessionLogin) {
        console.log(sessionLogin);
        console.log(sessionLogin.actor);
        this.activeSession = sessionLogin;
        console.log(this.activeSession.actor);
        AccountStore.accountAddress = this.activeSession.actor.toString();
        AccountStore.changeAccountAddress(this.activeSession.actor.toString());
        AccountStore.clearIntermidiateStage();
        AccountStore.setStage("menu");
        await this.fetchAllAssets("all");
      } else {
        this.handleError("Login Failed");
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  async login(data) {
    try {
      AccountStore.setUserData(data);
      AccountStore.accountAddress = data.accountName;
      AccountStore.clearIntermidiateStage();
      AccountStore.setStage("menu");
      await this.getAllTemplates();
      await this.fetchAllAssets("all");
    } catch (e) {
      this.handleError(e);
      AccountStore.clearIntermidiateStage();
    }
  }

  async reloadGame() {
    var current_stage = AccountStore.getStage();
    await this.getAllTemplates();

    switch (current_stage) {
      case "staking":
        await this.getAllCollNFTs();
        break;

      case "raid_menu":
        await this.fetchAllAssets("raids");
        break;

      default:
        await this.fetchAllAssets("all");
        break;
    }
  }

  async sendTransaction(actions) {
    AccountStore.set_loading(true);
    console.log(actions);
    return await this.activeSession.transact(
      { actions },
      { broadcast: true, expireSeconds: 300 }
    );
  }

  async getAllAssetsinMarketContract() {
    try {
      const path =
        "atomicassets/v1/assets?" +
        "&owner=" +
        this.marketContract +
        "&page=1&limit=1000&order=desc&sort=asset_id";
      const response = await fetch(this.atomic_api + "/" + path, {
        headers: {
          "Content-Type": "text/plain",
        },
        method: "POST",
      });

      const body = await response.json();
      const data = Object.values(body.data);
      console.log(data);

      var allAssets = data;
      return data;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getAllListings() {
    try {
      AccountStore.set_loading(true);
      var allAssets = await this.getAllAssetsinMarketContract();
      const data = JSON.stringify({
        json: true,
        code: this.marketContract,
        scope: this.marketContract,
        table: "listings",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      var result = [];
      var arr = Object.values(body.rows);
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          var data2 = arr[i];
          var asset_ids = data2.asset_ids;
          var asset_data = [];
          for (let j = 0; j < allAssets.length; j++) {
            var asset = allAssets[j];
            if (asset_ids.includes(asset.asset_id.toString())) {
              asset.video = asset.template.immutable_data?.video;
              asset.img = asset.template.immutable_data?.img;
              asset_data.push(asset);
            }
          }
          console.log(asset_data);

          console.log(data2);
          if (asset_data.length > 0) {
            result.push({
              id: data2.id,
              seller: data2.seller,
              schema: asset_data?.[0]?.schema?.schema_name,
              price: data2.price.split(" ")[0],
              video: asset_data?.[0]?.template?.immutable_data
                ? asset_data?.[0]?.template?.immutable_data.video
                : asset_data?.[0]?.template?.immutable_data?.img,
              currency: data2.price.split(" ")[1],
              assets: asset_data,
            });
          }
        }
        console.log(result);
        AccountStore.setListings(result);
        AccountStore.set_loading(false);
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  async sellItem(asset_ids, price, symbol) {
    //action to atomicasset transfer with memo of sell%price(int)%symbol
    try {
      var priceAmount =
        symbol === "WAX"
          ? parseFloat(price).toFixed(8)
          : parseFloat(price).toFixed(4);
      priceAmount = parseFloat(priceAmount) * 10000;
      var gza = priceAmount + " " + symbol;
      var actions = [];
      actions.push({
        account: "atomicassets",
        name: "transfer",
        authorization: [this.activeSession.permissionLevel],
        data: {
          from: AccountStore.accountAddress,
          to: this.marketContract,
          asset_ids: asset_ids,
          memo: "sell%" + price + "%" + symbol,
        },
      });
      await this.sendTransaction(actions);
      setTimeout(async () => {
        await this.getAllListings();
        await this.getUserBalance();
        this.handleSuccess("Successfully listed.");
      });
    } catch (e) {
      this.handleError(e);
    }
  }
  async cancelItem(id) {
    try {
      var actions = [];
      actions.push({
        account: this.marketContract,
        name: "cancellisting",
        authorization: [this.activeSession.permissionLevel],
        data: {
          id: id,
          player: AccountStore.accountAddress,
        },
      });
      await this.sendTransaction(actions);
      setTimeout(async () => {
        await this.getAllListings();
        await this.getUserBalance();
        this.handleSuccess("Successfully cancelled.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async buyItem(id, price, symbol) {
    try {
      var gza = parseFloat(price).toFixed(4) + " " + symbol;
      if (symbol === "WAX") gza = parseFloat(price).toFixed(8) + " " + symbol;
      var actions = [];
      if (symbol === "WAX") {
        actions.push({
          account: "eosio.token",
          name: "transfer",
          authorization: [this.activeSession.permissionLevel],
          data: {
            from: AccountStore.accountAddress,
            to: this.marketContract,
            quantity: gza,
            memo: "buy%" + id,
          },
        });
      } else {
        actions.push({
          account: this.marketContract,
          name: "buyitem",
          authorization: [this.activeSession.permissionLevel],
          data: {
            id: id,
            buyer: AccountStore.accountAddress,
          },
        });
      }
      await this.sendTransaction(actions);
      setTimeout(async () => {
        await this.getAllListings();
        await this.getUserBalance();
        this.handleSuccess("Successfully purchased.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async scavenge() {
    try {
      const actions = [
        {
          account: "radscavenger",
          name: "scavenge",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.accountAddress,
          },
        },
      ];
      await this.sendTransaction(actions);
      this.handleSuccess("Scavenge Started!");
      return true;
    } catch (e) {
      this.handleError(e);
      return false;
    }
  }

  async SwitchMode() {
    var currentMode = AccountStore.getMode();
    AccountStore.setMode(!currentMode);
    console.log(AccountStore.getMode());
  }

  async getscavInfo() {
    try {
      const accountAddressUint64 = this.eosjsName
        .nameToUint64(AccountStore.accountAddress)
        .toString();
      //get payments table info from radscavengers table, multi indexed by player name
      const data = JSON.stringify({
        json: true,
        code: "radscavenger",
        scope: "radscavenger",
        table: "payments",
        lower_bound: accountAddressUint64,
        upper_bound: accountAddressUint64,
        limit: 1,
        key_type: "i64",
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      //console.log(data);
      if (body.rows.length > 0) {
        console.log(body.rows[0]);
        return body.rows;
      } else return [];
    } catch (e) {
      this.handleError(e);
    }
  }

  async beginMultiRaids(raids, landFaction, landType) {
    if (!landType) {
      this.handleError({ message: "Please Select a Land type first" });
      return;
    }

    try {
      var actions = [];

      for (let i = 0; i < raids.length; i++) {
        var raidData = raids[i];
        const characterID = raidData.character?.asset_id || 0;
        const cID = raidData.commander?.asset_id || 0;
        const coID = raidData.companion?.asset_id || 0;
        const wID = raidData.weapon?.asset_id || 0;

        if (characterID === 0) {
          this.handleError({ message: "Please Select a character first" });
          return;
        }

        const tempData = [
          { key: "av", value: characterID },
          { key: "cp", value: cID },
          { key: "co", value: coID },
          { key: "w", value: wID },
        ];

        var finalData = [];

        tempData.forEach((item) => {
          if (item.value !== 0) finalData.push(item);
        });

        const booster = AccountStore.boost_types[raidData.boost] || 0;
        const bID = booster.name !== "No Boost" ? booster.template_id || 0 : 0;

        if (bID !== 0) finalData.push({ key: "boost", value: bID });

        actions.push({
          account: this.contract,
          name: "sexplore",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.getAccountAddress(),
            ids: finalData,
            land_faction: landFaction,
            land_type: landType,
          },
        });
      }

      var result = await this.sendTransaction(actions);

      setTimeout(async () => {
        this.handleSuccess("Successfully Started " + raids.length + " Raids!");
        await this.fetchAllAssets("raids");
      }, 10);
    } catch (e) {
      console.log(e);
      this.handleError(e);
    }
  }

  async beginRaid(raidData, landFaction, landType) {
    if (!landType) {
      this.handleError({ message: "Please Select a Land type first" });
      return;
    }

    try {
      const finalData = [];
      raidData = AccountStore.getRaidState();

      const characterID = raidData.characters.asset_id || 0;
      const cID = raidData.commanders.asset_id || 0;
      const coID = raidData.companions.asset_id || 0;
      const wID = raidData.weapons.asset_id || 0;

      if (characterID === 0) {
        this.handleError({ message: "Please Select a character first" });
        return;
      }

      const tempData = [
        { key: "av", value: characterID },
        { key: "cp", value: cID },
        { key: "co", value: coID },
        { key: "w", value: wID },
      ];

      tempData.forEach((item) => {
        if (item.value !== 0) finalData.push(item);
      });

      const booster = AccountStore.boost_types[raidData.boost] || 0;
      const bID = booster.name !== "No Boost" ? booster.template_id || 0 : 0;

      if (bID !== 0) finalData.push({ key: "boost", value: bID });

      const transResult = await this.sendTransaction([
        {
          account: this.contract,
          name: "sexplore",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.getAccountAddress(),
            ids: finalData,
            land_faction: landFaction,
            land_type: landType,
          },
        },
      ]);

      setTimeout(async () => {
        AccountStore.resetRaidObj();
        await this.fetchAllAssets("raids");
        this.handleSuccess("Successfully Started Raid!");
      }, 10);
    } catch (e) {
      console.log(e);
      this.handleError(e);
    }
  }

  get_rtime(endtime) {
    var current = new Date().getTime() / 1000;
    var tr = endtime - current;
    var time = new Date().getTime() + tr * 1000;
    if (tr <= 0) time = 0;
    var finalz = parseInt(time);
    //console.log(finalz);
    return finalz;
  }

  async registerAsset(asset_ids) {
    try {
      var name = "regnfts";
      if (AccountStore.selectedAsset.type === "lands") name = "regland";
      if (AccountStore.selectedAsset.type === "moons") name = "stakemoons";
      let transResult = await this.sendTransaction([
        {
          account: this.contract,
          name: name,
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.getAccountAddress(),
            asset_ids: asset_ids,
          },
        },
      ]);
      setTimeout(async () => {
        if (AccountStore.selectedAsset.type === "moons")
          await this.fetchAllAssets("all");
        else await this.fetchAllAssets("nfts");
        await this.handleSuccess("Successfully Registered.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async setbooster() {
    try {
      let transResult = await this.sendTransaction([
        {
          account: this.contract,
          name: "setbooster",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.getAccountAddress(),
          },
        },
      ]);
      setTimeout(async () => {
        await this.fetchAllAssets("all");
        this.handleSuccess("Successfully Set.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async deregisterAsset(asset_ids) {
    if (asset_ids.length === 0) {
      this.handleError({ message: "No Assets Available to DeRegister!" });
      return;
    }
    try {
      var name = "deregnft";
      if (AccountStore.selectedAsset.type === "lands") name = "deregland";
      if (AccountStore.selectedAsset.type === "moons") name = "unstakemoons";
      await this.sendTransaction([
        {
          account: this.contract,
          name: name,
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: asset_ids,
            player: AccountStore.getAccountAddress(),
          },
        },
      ]);
      setTimeout(async () => {
        if (AccountStore.selectedAsset.type === "moons")
          await this.fetchAllAssets("all");
        else await this.fetchAllAssets("nfts");
        this.handleSuccess("Successfully Registered.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async boostAsset(asset_ids, type) {
    try {
      await this.sendTransaction([
        {
          account: this.contract,
          name: "useboost",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.accountAddress,
            asset_ids: asset_ids,
            type: type,
          },
        },
      ]);
      setTimeout(async () => {
        await this.fetchAllAssets("nfts");
        await this.handleSuccess("Successfully boosted.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async depositRADS(amount, symbol) {
    try {
      await this.sendTransaction([
        {
          account: symbol === "RADS" ? this.tokenContract : this.tokenContract2,
          name: "transfer",
          authorization: [this.activeSession.permissionLevel],
          data: {
            from: AccountStore.accountAddress,
            to: this.contract,
            quantity: parseFloat(amount).toFixed(4) + " " + symbol,
            memo: "deposit",
          },
        },
      ]);
      setTimeout(async () => {
        await this.getUserBalance();
        this.handleSuccess("Successfully deposited.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async buyShopItem(id, amount, price, symbol) {
    if (amount <= 0) {
      this.handleError({ message: "Invalid amount!" });
      return;
    }
    try {
      //console.log(id);
      //console.log(amount);
      //console.log(price);
      var gza =
        parseFloat(
          parseFloat(price).toFixed(4) * parseFloat(amount || 1).toFixed(4)
        ).toFixed(4) +
        " " +
        symbol;
      await this.sendTransaction([
        {
          account: this.contract,
          name: "buyshopitem",
          authorization: [this.activeSession.permissionLevel],
          data: {
            id: id,
            player: AccountStore.accountAddress,
            deposit_amt: gza,
            quantity: amount,
          },
        },
      ]);
      setTimeout(async () => {
        await this.getUserBalance();
        await this.fetchAllAssets("shop");
        await this.handleSuccess("Successfully purchased.");
      }, 100);
    } catch (e) {
      this.handleError(e);
    }
  }

  async withdrawRADS(amount, symbol) {
    try {
      await this.sendTransaction([
        {
          account: this.contract,
          name: "withdrawtkn",
          authorization: [this.activeSession.permissionLevel],
          data: {
            player: AccountStore.accountAddress,
            quantity: parseFloat(amount).toFixed(4) + " " + symbol,
          },
        },
      ]);
      setTimeout(async () => {
        await this.getUserBalance();
        this.handleSuccess("Successfully deposited.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async claimRaid(ids) {
    try {
      if (ids.length === 0) {
        this.handleError({ message: "No Raids Available to Claim!" });
        return;
      }
      var allRaids = AccountStore.getRaids().filter((raid) =>
        ids.includes(raid.id)
      );
      var totalZomb = 0;
      var totalWuf = 0;
      var totalNfts = 0;
      for (var i = 0; i < allRaids.length; i++) {
        var asset = allRaids[i];
        var reward = asset.reward;

        console.log("Reward string:", reward); // Log the reward string for debugging

        var zomb = 0,
          wuf = 0,
          nfts = 0;

        // Check for the presence of '%' to handle NFTs
        if (reward.includes("%")) {
          var [zombPart, rest] = reward.split("%");
          zomb = parseFloat(zombPart) / 10000 || 0;

          // Check for the presence of 'w' to handle WUF
          if (rest.includes("w")) {
            var [nftPart, wufPart] = rest.split("w");
            nfts = parseFloat(nftPart) || 0;
            wuf = parseFloat(wufPart) || 0;
          } else {
            // If 'w' is not present, it means only NFTs are present
            nfts = parseFloat(rest) || 0;
          }
        } else if (reward.includes("w")) {
          // If '%' is not present but 'w' is, then it's ZOMB and WUF
          var [zombPart, wufPart] = reward.split("w");
          zomb = parseFloat(zombPart) / 10000 || 0;
          wuf = parseFloat(wufPart) || 0;
        } else {
          // Only ZOMB is present
          zomb = parseFloat(reward) / 10000 || 0;
        }

        console.log("Parsed ZOMB:", zomb);
        console.log("Parsed WUF:", wuf);
        console.log("Parsed NFT count:", nfts);

        totalZomb += zomb;
        totalNfts += nfts;
        totalWuf += wuf;
      }

      console.log(allRaids);
      await this.sendTransaction([
        {
          account: this.contract,
          name: "cexplore",
          authorization: [this.activeSession.permissionLevel],
          data: {
            raid_ids: ids,
            player: AccountStore.accountAddress,
          },
        },
      ]);
      var zombFloat = parseFloat(totalZomb).toFixed(4);
      var wufFloat = parseFloat(totalWuf).toFixed(4);
      setTimeout(async () => {
        this.handleSuccess(
          "Successfully claimed " +
            ids.length +
            " raids! You got " +
            zombFloat +
            " ZOMB and " +
            (totalWuf > 0 ? wufFloat + " WUF" : "") +
            " and " +
            totalNfts +
            " NFTs!"
        );
        await this.fetchAllAssets("raids");
      }, 1500);
    } catch (e) {
      this.handleError(e);
    }
  }

  async claimAllRocks(ids) {
    try {
      var actions = [];
      if (ids.length === 0) {
        this.handleError({ message: "No rocks to claim!" });
        return;
      }
      var staked = ids
        .filter((id) => {
          return id.staked && id.result !== 0;
        })
        .map((id) => {
          return id.asset_id;
        });

      if (staked.length > 0)
        actions.push({
          account: this.rockcontract,
          name: "claimrock",
          authorization: [this.activeSession.permissionLevel],
          data: {
            rock_ids: staked,
            player: AccountStore.accountAddress,
          },
        });
      var redeemable = ids
        .filter((id) => {
          return !id.staked;
        })
        .map((id) => {
          return id.asset_id;
        });
      if (redeemable.length > 0)
        actions.push({
          account: "atomicassets",
          name: "transfer",
          authorization: [this.activeSession.permissionLevel],
          data: {
            from: AccountStore.accountAddress,
            to: this.rockcontract,
            asset_ids: redeemable,
            memo: "rad_rocks_claim",
          },
        });
      console.log(actions);
      await this.sendTransaction(actions);
      setTimeout(async () => {
        await this.fetchAllAssets("rocks");
        this.handleSuccess("Successfully claimed.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async claimRock(ids) {
    try {
      await this.sendTransaction([
        {
          account: this.rockcontract,
          name: "claimrock",
          authorization: [this.activeSession.permissionLevel],
          data: {
            rock_ids: ids,
            player: AccountStore.accountAddress,
          },
        },
      ]);
      setTimeout(async () => {
        await this.fetchAllAssets("rocks");
        this.handleSuccess("Successfully claimed.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async claimLands(ids) {
    try {
      if (ids.length === 0) {
        this.handleError({ message: "No Claimable Lands!" });
        return;
      }
      await this.sendTransaction([
        {
          account: this.contract,
          name: "claimland",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: ids,
            player: AccountStore.accountAddress,
          },
        },
      ]);
      setTimeout(async () => {
        await this.fetchAllAssets("nfts");
        this.handleSuccess("Successfully claimed.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  async sendRock(ids) {
    try {
      await this.sendTransaction([
        {
          account: "atomicassets",
          name: "transfer",
          authorization: [this.activeSession.permissionLevel],
          data: {
            from: AccountStore.accountAddress,
            to: this.rockcontract,
            asset_ids: ids,
            memo: "rad_rocks_claim",
          },
        },
      ]);
      setTimeout(async () => {
        await this.fetchAllAssets("rocks");
        this.handleSuccess("Successfully claimed.");
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }

  get_asset_boost(asset) {
    var boost = this.split_boosts(asset);
    var rboost = boost.rboost;
    var nboost = boost.nboost;
    var tboost = boost.tboost;
    var final = "";
    if (rboost > 0) final += "ZOMB(+" + rboost + " %)";
    if (nboost > 0) final += "NFT +(" + nboost + " %)";
    if (tboost > 0) final += "Time -(" + tboost + " %)";
    return final;
  }

  split_boosts(asset) {
    var rboost = 0.0;
    var nboost = 0.0;
    var tboost = 0.0;
    var gg = asset != undefined && asset && asset.boosts ? asset.boosts : 0;
    if (gg !== 0) {
      for (let j = 0; j < gg.length; j++) {
        if (gg[j].key === "rb") {
          rboost = parseFloat(gg[j].value) / 10000;
        } else if (gg[j].key === "nb") {
          nboost = parseFloat(gg[j].value) / 10000;
        } else if (gg[j].key === "tb") {
          tboost = parseFloat(gg[j].value) / 10000;
        }
      }
    }
    var total = rboost + nboost + tboost;
    return { rboost, nboost, tboost, total };
  }

  async checkAssetIds(type) {
    try {
      var table_data = [];
      const accountAddressUint64 = this.eosjsName
        .nameToUint64(AccountStore.accountAddress)
        .toString();
      const dataz = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: type,
        key_type: `i64`,
        index_position: 2,
        lower_bound: accountAddressUint64,
        upper_bound: accountAddressUint64,
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: dataz,
        method: "POST",
      });
      var body = await response.json();

      var arr = Object.values(body.rows);
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          var data = arr[i];
          if (data.owner === AccountStore.accountAddress) {
            if (type === "nfts") {
              table_data.push({
                asset_id: data.asset_id,
                raid_id: data.raid_id,
                boosts: data.boosts,
                faction: data.faction,
                cooldown: data.cooldown,
              });
            } else if (type === "raids") {
              table_data.push({
                index: i,
                id: data.id,
                config_id: data.config_id,
                l_type: data.l_type,
                end_time: data.end_time,
                ids: data.ids,
                boosts: data.boosts,
                reward: data.reward,
              });
            } else if (type === "lands") {
              table_data.push({
                asset_id: data.asset_id,
                type: data.faction,
                fee: data.fee,
                to_claim: data.to_claim,
                boosts: data.boosts,
              });
            }
          } else break;
        }
      }
      return table_data;
    } catch (e) {}
  }

  async checkRocks() {
    try {
      var table_data = [];
      const accountAddressUint64 = this.eosjsName
        .nameToUint64(AccountStore.accountAddress)
        .toString();
      const dataz = JSON.stringify({
        json: true,
        code: this.rockcontract,
        scope: this.rockcontract,
        table: "rocks",
        key_type: `i64`,
        index_position: 2,
        lower_bound: accountAddressUint64,
        upper_bound: accountAddressUint64,
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: dataz,
        method: "POST",
      });
      var body = await response.json();
      //console.log(body);
      var arr = Object.values(body.rows);
      if (arr.length !== 0) {
        for (let i = 0; i < arr.length; i++) {
          var data = arr[i];
          if (data.player === AccountStore.accountAddress) {
            table_data.push({
              asset_id: data.asset_id,
              result: data.result,
              index: data.index,
              staked: true,
            });
          } else break;
        }
      }
      return table_data;
    } catch (e) {}
  }

  async getShopData() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "shop",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      let result = [];
      if (body.rows.length !== 0) {
        for (const data of Object.values(body.rows)) {
          result.push({
            id: data.id,
            type: data.type,
            template_id: data.template_id,
            schema: data.schema,
            available: data.available,
            price: data.price,
            price2: data.price2,
            resource: data.resource,
          });
        }
      }
      var promises = await this.GetTemplate(result);
      console.log("Shop Data: ", promises);
      return promises;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getAllTemplates() {
    var path =
      "atomicassets/v1/templates?collection_name=" + this.collectionName;
    const response2 = await fetch(this.atomic_api + "/" + path, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
    });
    const body2 = await response2.json();
    var path2 =
      "atomicassets/v1/templates?collection_name=" + this.collectionName2;
    const response3 = await fetch(this.atomic_api + "/" + path2, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
    });
    const body3 = await response3.json();
    const data2 = Object.values(body2.data);
    const data3 = Object.values(body3.data);
    var templates = data2.concat(data3);
    console.log(data2);
    console.log(data3);
    console.log("All Templates: ", templates);
    this.allTemplates = templates || [];
  }

  async GetTemplate(shopData) {
    var finalData = [];
    for (let i = 0; i < shopData.length; i++) {
      var x = shopData[i];
      var body = this.allTemplates.filter(
        (y) => parseInt(y.template_id) === parseInt(x.template_id)
      )?.[0];
      console.log(this.allTemplates);
      console.log(body);
      console.log(x);
      var data = {
        id: x.id,
        template_id: x.template_id,
        type: x.type,
        schema: x.schema,
        available: x.available,
        price: x.price,
        price2: x.price2,
        resource: x.resource,
        img: body?.immutable_data.img
          ? body?.immutable_data.img
          : body?.immutable_data.video,
        video: body?.immutable_data.video
          ? body?.immutable_data.video
          : body?.immutable_data.video,
        name: body?.immutable_data.name,
      };
      console.log(data);
      finalData.push(data);
    }

    return finalData;
  }

  async sortBType(dd) {
    const promisesToAwait = [];
    for (let i = 0; i < dd.length; i++) {
      promisesToAwait.push(this.GetBTemplate(dd[i]));
    }
    return promisesToAwait;
  }

  async GetBTemplate(x) {
    var final = {};
    var path =
      "atomicassets/v1/templates/" + this.collectionName + "/" + x.template_id;
    const response = await fetch(this.atomic_api + "/" + path, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
    });
    const body = await response.json();
    final = {
      template_id: x.template_id,
      boost: x.boost,
      schema: x.schema,
      faction: x.faction,
      rb:
        x.values.filter((x) => x.key === "rb").length > 0
          ? x.values.filter((x) => x.key === "rb")[0].value
          : 0,
      nb:
        x.values.filter((x) => x.key === "nb").length > 0
          ? x.values.filter((x) => x.key === "nb")[0].value
          : 0,
      img: body.data.immutable_data.img
        ? body.data.immutable_data.img
        : body.data.immutable_data.video,
      video: body.data.immutable_data.video
        ? body.data.immutable_data.video
        : body.data.immutable_data.video,
      name: body.data.immutable_data.name,
      rarity: body.data.immutable_data.rarity,
    };
    return final;
  }
  async getBoostTypes() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "bconfigs",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      let obj = [];
      for (const data of Object.values(body.rows)) {
        if (data.boost.includes("MOON")) continue;
        obj.push(data);
      }
      if (obj.length > 0) {
        const promises = await this.sortBType(obj);
        const responses = await Promise.all(promises);
        console.log("Boost Data: ", responses);
        return responses;
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  async getConfig() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "configs",
        limit: 1,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      let obj = [];
      if (body.rows.length !== 0) {
        let data = body.rows[0];
        obj.push(data);
      }
      return obj;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getTConfig() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "tconfigs",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      let obj = [];
      if (body.rows.length !== 0) {
        for (const data of Object.values(body.rows)) {
          obj.push({
            template_id: data.template_id,
            type: data.type,
            rarity: data.rarity,
            values: data.values,
          });
        }
      }
      return obj;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getAssets(schema) {
    try {
      if (schema === "all") {
        var more = true;
        var pageno = 1;
        var finalData = [];
        while (more) {
          const path =
            "atomicassets/v1/assets?collection_name=" +
            this.collectionName +
            "&owner=" +
            AccountStore.accountAddress +
            "&limit=1000&order=desc&sort=asset_id" +
            "&template_blacklist=742640&page=" +
            pageno;
          const response = await fetch(this.atomic_api + "/" + path, {
            headers: {
              "Content-Type": "text/plain",
            },
            method: "POST",
          });

          const body = await response.json();
          const data = Object.values(body.data);
          console.log(data);
          for (const asset of data) {
            finalData.push({
              asset_id: asset.asset_id,
              img: asset.data.img,
              imgback: asset.data.imgback,
              video: asset.data.video,
              template_id: asset.template.template_id,
              name: asset.template.immutable_data.name,
              schema: asset.schema.schema_name,
              rarity: asset.template.immutable_data.Rarity
                ? asset.template.immutable_data.Rarity
                : asset.template.immutable_data.rarity
                ? asset.template.immutable_data.rarity
                : "Common",
              faction: asset.template.immutable_data.faction
                ? asset.template.immutable_data.faction
                : "Zombie",
              level:
                typeof asset.mutable_data.Level != "undefined"
                  ? asset.mutable_data.Level
                  : true,
              staked: false,
            });
          }
          console.log(data.length);
          pageno++;
          more = data.length === 1000 || false;
        }
        return finalData;
      } else {
        const path =
          "/atomicassets/v1/assets?collection_name=" +
          this.collectionName +
          "&schema_name=" +
          schema +
          "&owner=" +
          AccountStore.accountAddress +
          "&page=1&limit=1000&order=desc&sort=asset_id";
        const response = await fetch(this.atomic_api + path, {
          headers: {
            "Content-Type": "text/plain",
          },
          method: "POST",
        });

        const body = await response.json();
        const data = Object.values(body.data);
        const obj = [];
        for (const asset of data) {
          obj.push({
            asset_id: asset.asset_id,
            img: asset.data.img,
            imgback: asset.data.imgback,
            video: asset.data.video,
            template_id: asset.template.template_id,
            name: asset.template.immutable_data.name,
            schema: asset.schema.schema_name,
            rarity: asset.template.immutable_data.Rarity
              ? asset.template.immutable_data.Rarity
              : asset.template.immutable_data.rarity
              ? asset.template.immutable_data.rarity
              : "Common",
            faction: asset.template.immutable_data.faction
              ? asset.template.immutable_data.faction
              : "Zombie",
            level:
              typeof asset.mutable_data.Level != "undefined"
                ? asset.mutable_data.Level
                : true,
            staked: false,
          });
        }
        return obj;
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  async getUserBalance() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: AccountStore.accountAddress,
        table: "balances",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      const vad = body.rows;
      var balancess = [];
      for (let i = 0; i < vad.length; i++) {
        balancess.push(vad[i].balance);
      }
      AccountStore.setUserBalance(balancess);

      var walletbalance = await this.getUserWBalance();
      AccountStore.set_loading(false);
      return vad;
    } catch (e) {
      this.handleError(e);
    }
  }
  async getUserWBalance() {
    try {
      var data = JSON.stringify({
        json: true,
        code: this.tokenContract,
        scope: AccountStore.accountAddress,
        table: "accounts",
        limit: 1000,
      });
      var response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      var body = await response.json();
      var vad = body.rows;
      var balancess = [];
      for (let i = 0; i < vad.length; i++) {
        if (vad[i].balance.includes("RADS")) {
          balancess.push(vad[i].balance);
        }
      }

      data = JSON.stringify({
        json: true,
        code: this.tokenContract2,
        scope: AccountStore.accountAddress,
        table: "accounts",
        limit: 1000,
      });
      response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      body = await response.json();
      vad = body.rows;
      for (let i = 0; i < vad.length; i++) {
        if (vad[i].balance.includes("ZOMB")) {
          balancess.push(vad[i].balance);
        }
      }
      AccountStore.setuserWBalance(balancess);
      return vad;
    } catch (e) {
      this.handleError(e);
    }
  }

  async getExploreTypes() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "rconfigs",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });
      const body = await response.json();
      if (!body.rows) return [];
      if (body.rows.length !== 0) {
        let obj = [];
        const bodyData = Object.values(body.rows);
        for (const data of bodyData) {
          obj.push(data);
        }
        return obj;
      } else return [];
    } catch (e) {
      this.handleError(e);
    }
  }

  async getNFTData(type) {
    try {
      let assetData = await this.getAssets(type);
      return assetData;
    } catch (e) {
      this.handleError(e);
    }
  }

  async filternft(allnfts, stakednfts) {
    for (let i = 0; i < allnfts.length; i++) {
      for (let j = 0; j < stakednfts.length; j++) {
        if (allnfts[i].asset_id === stakednfts[j].asset_id) {
          allnfts[i].staked = true;
          allnfts[i].stakedData = stakednfts[j];
          //console.log("XXXXXXX");
        }
      }
    }
    return allnfts;
  }

  get_Conf(asset) {
    var config = "";
    var rconfigs = AccountStore.configs.explore_types;
    var config_id = asset.config_id;
    for (let i = 0; i < rconfigs.length; i++) {
      if (rconfigs[i].id === config_id) {
        config = rconfigs[i];
      }
    }
    var final = "";
    if (config !== "") {
    }
    return final;
  }

  get_reward(asset) {
    var reward = "Failed Raid!";

    if (asset.reward) {
      if (asset.reward === "wait") return "Waiting";
      if (asset.reward.includes("fail")) return "Failed Raid!";
      reward = asset.reward;
      var rewardSplit = reward.split("%");
      var wufSplit = reward.split("w");
      var zomb = (parseFloat(rewardSplit[0]) / 10000).toFixed(4) + " ZOMB ";
      var hasWuf = wufSplit?.[1] || null;
      if (hasWuf) {
        var wuf = parseFloat(hasWuf);
        zomb += " + " + wuf + " WUF";
        if (rewardSplit.length > 1) rewardSplit[1] = wufSplit[0];
      }
      var nfts = rewardSplit.length > 1 ? parseFloat(rewardSplit[1]) : 0;
      var nft = "";
      if (nfts === 1) nft = "+ 1 ROCK FOUND!";
      else if (nfts === 2) nft = "+ 1 FRAGMENT FOUND!";
      return "Result:" + zomb + nft;
    }
    return reward;
  }

  getConfigData(configId) {
    var allConfigs = AccountStore.configs?.explore_types;
    var configs = allConfigs.filter((a) => a.id === configId);
    if (configs.length === 0) return null;
    var config = configs[0];
    console.log(config);
    console.log(config.player_faction);
    var playFaction =
      config.player_faction == 1
        ? "Zombie"
        : config.player_faction == 2
        ? "Reborn"
        : "Hunter";
    var landFaction =
      config.land_faction == 1
        ? "Zombie"
        : config.land_faction == 2
        ? "Reborn"
        : "Hunter";
    var landType =
      config.land_type == 1
        ? "Zomb Heavy"
        : config.land_type == 2
        ? "Equal"
        : "NFT Heavy";
    var finalObj = {
      player_faction: playFaction,
      land_faction: landFaction,
      land_type: landType,
    };
    return finalObj;
  }

  async getLeaderboard(type) {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: type,
        table: "rankings",
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });

      const body = await response.json();
      if (!body.rows) return [];
      if (body.rows.length !== 0) {
        let obj = [];
        const bodyData = Object.values(body.rows);
        for (const data of bodyData) {
          obj.push(data);
        }
        return obj;
      } else return [];
    } catch (e) {
      this.handleError(e);
    }
  }

  async getMoonBoost() {
    try {
      const data = JSON.stringify({
        json: true,
        code: this.contract,
        scope: this.contract,
        table: "pboosts",
        lower_bound: AccountStore.accountAddress,
        upper_bound: AccountStore.accountAddress,
        limit: 1000,
      });
      const response = await fetch(this.endpoint + this.path, {
        headers: {
          "Content-Type": "text/plain",
        },
        body: data,
        method: "POST",
      });

      const body = await response.json();
      console.log("mboost", body);
      if (body.rows?.length > 0) {
        console.log("mboost", body.rows[0]);
        return body.rows[0];
      }
    } catch (e) {
      this.handleError(e);
    }
  }

  async fetchAllAssets(type) {
    AccountStore.set_loading(true);
    if (this.allTemplates.length === 0) await this.getAllTemplates();
    if (type === "all") {
      AccountStore.setConfigType("explores", await this.getExploreTypes());
      AccountStore.setConfigType("nfts", await this.getTConfig());
      AccountStore.setConfigType("general", await this.getConfig());
      AccountStore.setMoonBoostInfo(await this.getMoonBoost());
      let all = await this.getNFTData("all");
      let characterz = all.filter((a) => a.schema === "characters");
      let companionz = all.filter((a) => a.schema === "companions");
      let landz = all.filter((a) => a.schema === "lands");
      let weaponz = all.filter((a) => a.schema === "weapons");
      let commanderz = all.filter((a) => a.schema === "commanders");
      console.log("ALLLLLL", all);
      let rocks = all.filter((a) => a.template_id === "609944");
      let moons = all.filter(
        (a) => a.schema === "boosters" && a.name === "Moon"
      );
      console.log("MOONS", moons);
      let stakedMoons = await this.getAllStakedMoons();
      let check_nfts = await this.checkAssetIds("nfts");
      let check_lands = await this.checkAssetIds("lands");
      let check_raids = await this.checkAssetIds("raids");
      let check_rocks = await this.checkRocks();
      rocks = rocks.concat(check_rocks);
      //console.log(rocks);
      AccountStore.setUserAssets(
        await this.filternft(characterz, check_nfts),
        await this.filternft(companionz, check_nfts),
        await this.filternft(landz, check_lands),
        await this.filternft(commanderz, check_nfts),
        await this.filternft(weaponz, check_nfts),
        rocks,
        await this.filternft(moons, stakedMoons)
      );
      let vv = await this.getBoostTypes();
      AccountStore.set_btypes(vv);
      AccountStore.setRaidobj(check_raids);
      let balances = await this.getUserBalance();
      let shopData = await this.getShopData();
      AccountStore.setShopData(shopData);
      var stakingAssets = await this.getAllCollNFTs();
    } else {
      if (type === "nfts" || type == "raids") {
        let all = await this.getNFTData("all");
        let characterz = all.filter((a) => a.schema === "characters");
        let companionz = all.filter((a) => a.schema === "companions");
        let landz = all.filter((a) => a.schema === "lands");
        let weaponz = all.filter((a) => a.schema === "weapons");
        let commanderz = all.filter((a) => a.schema === "commanders");
        let check_nfts = await this.checkAssetIds("nfts");
        let check_lands = await this.checkAssetIds("lands");
        let balances = await this.getUserBalance();
        let rocks = AccountStore.userAssets.radrocks
          ? AccountStore.userAssets.radrocks
          : [];

        AccountStore.setUserAssets(
          await this.filternft(characterz, check_nfts),
          await this.filternft(companionz, check_nfts),
          await this.filternft(landz, check_lands),
          await this.filternft(commanderz, check_nfts),
          await this.filternft(weaponz, check_nfts),
          rocks
        );
        if (type === "raids")
          AccountStore.setRaidobj(await this.checkAssetIds("raids"));
      } else if (type === "rocks") {
        let all = await this.getNFTData("radrocks");
        var rocks = all.filter((a) => a.template_id === "609944");
        let check_rocks = await this.checkRocks();
        var allrocks = rocks.concat(check_rocks);
        AccountStore.setUserAssets(
          AccountStore.userAssets.characters,
          AccountStore.userAssets.companions,
          AccountStore.userAssets.lands,
          AccountStore.userAssets.commanders,
          AccountStore.userAssets.weapons,
          allrocks
        );
      } else if (type === "boosts") {
        let vv = await this.getBoostTypes();
        AccountStore.set_btypes(vv);
      } else if (type === "shop") {
        let shopData = await this.getShopData();
        AccountStore.setShopData(shopData);
      } else if (type === "balance") {
        let balances = await this.getUserBalance();
      }
    }
    AccountStore.set_loading(false);
    //console.log("user assets: ", toJS(AccountStore.getUserAssets()));
  }

  logOut() {
    this.sessionKit.logout();
    AccountStore.clearAllData();
  }
  async getAllMiners(allCol1NFTs) {
    AccountStore.set_loading(true);
    var allNFTs = allCol1NFTs;
    var allStakedNFts = await this.getAllStakedMiners();
    var minerConfigs = await this.getMinerTable("wufconfigs");
    var minerPools = await this.getMinerTable("wufpools");
    console.log("all staked nfts: ", allStakedNFts);
    for (var i = 0; i < allNFTs.length; i++) {
      allNFTs[i].staked = false;
      var staked_data = allStakedNFts.find(
        (a) => parseInt(a.asset_id) === parseInt(allNFTs[i].asset_id)
      );
      if (staked_data) {
        allNFTs[i].staked = true;
        allNFTs[i].level = staked_data.level || 0;
        allNFTs[i].last_claim = staked_data.last_claim;
        allNFTs[i].stake_config = staked_data;
      }
      var template_id = parseInt(allNFTs[i].template.template_id);
      var config = minerConfigs?.find(
        (a) => parseInt(a.template_id) === template_id
      );
      if (config) {
        allNFTs[i].config = config;
        allNFTs[i].rate = config.wuf_per_day;
        var pool = minerPools?.find((a) => a.id === config.pool_id);
        if (pool) {
          allNFTs[i].pool = pool;
        }
      }
    }
    allNFTs = allNFTs.filter((nft) => nft.config);
    AccountStore.setWufMiners(allNFTs);
    AccountStore.setWufConfigs(minerConfigs);
    AccountStore.setWufPools(minerPools);
    AccountStore.set_loading(false);
    console.log("Datas1", allNFTs);
    console.log("Datas2", minerConfigs);
    console.log("Datas3", minerPools);
    console.log("Datas4", AccountStore.getWufMiners());
    return allNFTs;
  }
  async getLevelTempRates() {
    var data = JSON.stringify({
      json: true,
      code: this.contract,
      scope: this.contract,
      table: "stakerates",
      limit: 1000,
    });

    var response = await fetch(this.endpoint + "/v1/chain/get_table_rows", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    var body = await response.json();
    var rates = body.rows;
    console.log(rates);
    return rates;
  }

  async getAllCollNFTs() {
    AccountStore.set_loading(true);
    var rates = await this.getLevelTempRates();
    var more = true;
    var pageno = 1;
    var allCol1NFTs = [];
    while (more) {
      const path =
        "atomicassets/v1/assets?collection_name=" +
        this.collectionName +
        "&owner=" +
        AccountStore.accountAddress +
        "&page=" +
        pageno +
        "&limit=1000&order=desc&sort=asset_id" +
        "&template_blacklist=742640";
      const response = await fetch(this.atomic_api + "/" + path, {
        headers: {
          "Content-Type": "text/plain",
        },
        method: "POST",
      });
      const body = await response.json();
      console.log("body", body);
      console.log("response", response);
      allCol1NFTs.push(...Object.values(body.data));
      pageno++;
      if (body.data.length < 1000) more = false;
    }
    await this.getAllMiners(allCol1NFTs);

    const coll1NFTs = allCol1NFTs;
    const path2 =
      "atomicassets/v1/assets?collection_name=" +
      this.collectionName2 +
      "&owner=" +
      AccountStore.accountAddress +
      "&page=1&limit=1000&order=desc&sort=asset_id";
    const response2 = await fetch(this.atomic_api + "/" + path2, {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
    });

    const body2 = await response2.json();
    const coll2NFTs = Object.values(body2.data);
    console.log("coll1NFTs: ", coll1NFTs);
    console.log("coll2NFTs: ", coll2NFTs);

    var allNFTs = coll1NFTs.concat(coll2NFTs);
    var allStakedNFts = await this.getAllStakeNFTs();
    console.log("all staked nfts: ", allStakedNFts);
    for (var i = 0; i < allNFTs.length; i++) {
      allNFTs[i].staked = false;
      for (var j = 0; j < allStakedNFts.length; j++) {
        if (allNFTs[i].asset_id === allStakedNFts[j].asset_id) {
          allNFTs[i].staked = true;
          allNFTs[i].level = allStakedNFts[j].level;
          allNFTs[i].last_claim = allStakedNFts[j].last_claim;
        }
      }
      var template_id = parseInt(allNFTs[i].template.template_id);
      for (var k = 0; k < rates?.length; k++) {
        var template_ids = rates[k].template_ids;
        if (template_ids.includes(template_id)) {
          console.log(
            "template id: ",
            template_id,
            " level: ",
            rates[k].level,
            " rate: ",
            rates[k].rate
          );
          allNFTs[i].level = rates[k].level;
          allNFTs[i].rate = rates[k].rate_day[0];
          allNFTs[i].rarity = allNFTs[i].template.immutable_data.Rarity
            ? allNFTs[i].template.immutable_data.Rarity
            : "Common";
        }
      }
    }
    allNFTs = allNFTs.filter((nft) => nft.rate);
    console.log("all nfts: ", allNFTs);
    AccountStore.set_stakingAssets(allNFTs);
    AccountStore.set_loading(false);
    return allNFTs;
  }

  async getAllStakedMoons() {
    const data = JSON.stringify({
      json: true,
      code: this.contract,
      scope: AccountStore.accountAddress,
      table: "moons",
      limit: 1000,
      reverse: false,
    });
    const response = await fetch(this.endpoint + this.path, {
      headers: {
        "Content-Type": "text/plain",
      },
      body: data,
      method: "POST",
    });
    const body = await response.json();
    const allNFTs = Object.values(body.rows);
    return allNFTs;
  }

  async getAllStakeNFTs() {
    var coll1Name = this.eosjsName.nameToUint64("talesofcrypt");
    var coll2Name = this.eosjsName.nameToUint64("cryptodeadnw");
    var allnfts = [];
    const data = JSON.stringify({
      json: true,
      code: this.contract,
      scope: AccountStore.accountAddress,
      table: "stakenfts",
      lower_bound: coll1Name,
      upper_bound: coll1Name,
      key_type: "i64",
      limit: 1000,
      index_position: 2,
      reverse: false,
    });
    const response = await fetch(this.endpoint + "/v1/chain/get_table_rows", {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
      body: data,
    });
    const body = await response.json();
    const allNFTs = Object.values(body.rows);

    const data2 = JSON.stringify({
      json: true,
      code: this.contract,
      scope: AccountStore.accountAddress,
      table: "stakenfts",
      lower_bound: coll2Name,
      upper_bound: coll2Name,
      key_type: "i64",
      limit: 1000,
      index_position: 2,
      reverse: false,
    });

    const response2 = await fetch(this.endpoint + "/v1/chain/get_table_rows", {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
      body: data2,
    });
    const body2 = await response2.json();
    const allNFTs2 = Object.values(body2.rows);
    allnfts = allNFTs.concat(allNFTs2);
    return allnfts;
  }
  async getMinerTable(table) {
    const data = JSON.stringify({
      json: true,
      code: this.contract,
      scope: this.contract,
      table: table,
      limit: 1000,
      reverse: false,
    });
    const response = await fetch(this.endpoint + "/v1/chain/get_table_rows", {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
      body: data,
    });
    const body = await response.json();
    const allNFTs = Object.values(body.rows);
    return allNFTs || [];
  }
  async getAllStakedMiners() {
    var accountName = this.eosjsName.nameToUint64(AccountStore.accountAddress);
    const data = JSON.stringify({
      json: true,
      code: this.contract,
      scope: this.contract,
      table: "wufnfts",
      limit: 1000,
      lower_bound: accountName,
      upper_bound: accountName,
      index_position: 2,
      key_type: "i64",
      reverse: false,
    });
    const response = await fetch(this.endpoint + "/v1/chain/get_table_rows", {
      headers: {
        "Content-Type": "text/plain",
      },
      method: "POST",
      body: data,
    });
    const body = await response.json();
    var allNFTs = Object.values(body.rows);
    return allNFTs;
  }

  async claimStaking(asset_ids) {
    try {
      if (!asset_ids.length || asset_ids.length === 0) {
        this.handleError("No claimable NFTs");
        return;
      }
      var actions = [];
      var asset_ids_groups = [];
      //split asset_ids into groups of 200 if more than 200
      if (asset_ids.length > 100) {
        var asset_ids_group = [];
        for (var i = 0; i < asset_ids.length; i++) {
          asset_ids_group.push(asset_ids[i]);
          if (asset_ids_group.length == 100) {
            asset_ids_groups.push(asset_ids_group);
            asset_ids_group = [];
          }
        }
        if (asset_ids_group.length > 0) {
          asset_ids_groups.push(asset_ids_group);
        }
      } else {
        asset_ids_groups.push(asset_ids);
      }
      //
      for (var i = 0; i < asset_ids_groups.length; i++) {
        var asset_ids_group = asset_ids_groups[i];
        actions.push({
          account: this.contract,
          name: "claimstake",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: asset_ids_group,
            player: AccountStore.accountAddress,
          },
        });
      }
      actions = actions.slice(0, 3);
      await this.sendTransaction(actions);
      await this.getAllCollNFTs();
      await this.getUserBalance();
    } catch (e) {
      this.handleError(e);
    }
  }

  async claimMiners(asset_ids) {
    try {
      if (!asset_ids.length || asset_ids.length === 0) {
        this.handleError("No claimable NFTs");
        return;
      }
      var actions = [];
      var asset_ids_groups = [];
      //split asset_ids into groups of 200 if more than 200
      if (asset_ids.length > 100) {
        var asset_ids_group = [];
        for (var i = 0; i < asset_ids.length; i++) {
          asset_ids_group.push(asset_ids[i]);
          if (asset_ids_group.length == 100) {
            asset_ids_groups.push(asset_ids_group);
            asset_ids_group = [];
          }
        }
        if (asset_ids_group.length > 0) {
          asset_ids_groups.push(asset_ids_group);
        }
      } else {
        asset_ids_groups.push(asset_ids);
      }
      //
      for (var i = 0; i < asset_ids_groups.length; i++) {
        var asset_ids_group = asset_ids_groups[i];
        actions.push({
          account: this.contract,
          name: "claimwufm",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: asset_ids_group,
            player: AccountStore.accountAddress,
          },
        });
      }
      actions = actions.slice(0, 3);
      await this.sendTransaction(actions);
      await this.getAllCollNFTs();
    } catch (e) {
      this.handleError(e);
    }
  }

  async unstakeNFT(ids) {
    try {
      await this.sendTransaction([
        {
          account: this.contract,
          name: "unstakenfts",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: ids,
            player: AccountStore.accountAddress,
          },
        },
      ]);
      await this.getAllCollNFTs();
    } catch (e) {
      this.handleError(e);
    }
  }

  async stakeNFT(asset_ids) {
    try {
      if (!asset_ids.length || asset_ids.length === 0) {
        this.handleError("No stakable NFTs");
        return;
      }
      var actions = [];
      var asset_ids_groups = [];
      //split asset_ids into groups of 200 if more than 200
      if (asset_ids.length > 50) {
        var asset_ids_group = [];
        for (var i = 0; i < asset_ids.length; i++) {
          asset_ids_group.push(asset_ids[i]);
          if (asset_ids_group.length == 200) {
            asset_ids_groups.push(asset_ids_group);
            asset_ids_group = [];
          }
        }
        if (asset_ids_group.length > 0) {
          asset_ids_groups.push(asset_ids_group);
        }
      } else {
        asset_ids_groups.push(asset_ids);
      }

      for (var i = 0; i < asset_ids_groups.length; i++) {
        var asset_ids_group = asset_ids_groups[i];
        actions.push({
          account: this.contract,
          name: "stakenfts",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: asset_ids_group,
            player: AccountStore.accountAddress,
          },
        });
      }
      if (actions.length > 5) actions = actions.split(0, 5);
      await this.sendTransaction(actions);
      setTimeout(async () => {
        this.handleSuccess("Successfully staked " + asset_ids.length + " NFTs");
        await this.getAllCollNFTs();
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }
  async stakeMiner(asset_ids) {
    try {
      if (!asset_ids.length || asset_ids.length === 0) {
        this.handleError("No stakable NFTs");
        return;
      }
      var actions = [];
      var asset_ids_groups = [];
      //split asset_ids into groups of 200 if more than 200
      if (asset_ids.length > 50) {
        var asset_ids_group = [];
        for (var i = 0; i < asset_ids.length; i++) {
          asset_ids_group.push(asset_ids[i]);
          if (asset_ids_group.length == 200) {
            asset_ids_groups.push(asset_ids_group);
            asset_ids_group = [];
          }
        }
        if (asset_ids_group.length > 0) {
          asset_ids_groups.push(asset_ids_group);
        }
      } else {
        asset_ids_groups.push(asset_ids);
      }

      for (var i = 0; i < asset_ids_groups.length; i++) {
        var asset_ids_group = asset_ids_groups[i];
        actions.push({
          account: this.contract,
          name: "stakewufm",
          authorization: [this.activeSession.permissionLevel],
          data: {
            asset_ids: asset_ids_group,
            player: AccountStore.accountAddress,
          },
        });
      }
      if (actions.length > 5) actions = actions.split(0, 5);
      await this.sendTransaction(actions);
      setTimeout(async () => {
        this.handleSuccess("Successfully staked " + asset_ids.length + " NFTs");
        await this.getAllCollNFTs();
      }, 10);
    } catch (e) {
      this.handleError(e);
    }
  }
}

export default new FunctionStore();
