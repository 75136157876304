import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import { useEffect, useState, useContext } from "react";
import { ReactComponent as WalletArrowRight } from "../../assets/icons/choose-wallet-arrow-right.svg";
import ContentBox from "../../components/contentBox";
import "../Raid_Menu/index.sass";
import "./index.sass";

function Login() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [WaxType, SetWaxType] = useState(false);
  const [utilityType, SetutilityType] = useState(false);

  const Dropdown = ({ label, value, options, onChange }) => {
    return (
      <label className="optionDropDown">
        {label}
        <select value={value} onChange={onChange} className="optionSelect">
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </label>
    );
  };

  async function loginz() {
    await FunctionStore.wharfLogin();
  }

  const handleWaxType = (event) => {
    AccountStore.set_endpoint(event.target.value);
    SetWaxType(event.target.value);
  };

  const handleAtomicType = (event) => {
    AccountStore.set_aaendpoint(event.target.value);
    SetutilityType(event.target.value);
  };

  return AccountStore.zoomed ? (
    <div className="menu">
      <div
        className="loginOption3"
        onClick={() => AccountStore.set_zoomed(false)}
      />
      <ContentBox title="" subtitle="">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",
            gap: "20px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../assets/toc/login.png")}
            alt="inventory"
            className="loginOption1"
            onClick={() => loginz()}
          />
        </div>
      </ContentBox>
    </div>
  ) : (
    <>
      <div className="menu">
        <div
          className="loginOption3"
          onClick={() => AccountStore.set_zoomed(true)}
        />
      </div>
    </>
  );
}

export default observer(Login);
