import './index.sass'
import ReactDOM from "react-dom";

export default function Modal({opened, setOpened, children}) {
    return (
        <>
            {ReactDOM.createPortal(<div className={`modal-overlay ${opened ? "modal-overlay-opened" : ""}`}>
                <div className="modal-content">
                    {children}
                </div>
            </div>, document.body)}
        </>
    )
}