import { observer } from "mobx-react";
import "./index.sass";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import LevelUpModal from "../../components/levelUpModal";
import BoostModal from "../../components/boostModal";
import "../Invent/index.sass";
import Preloader from "../../components/preloader";

function Statistics() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [selectedtype, setSelectedtype] = useState("staked");
  const [value, setValue] = useState("All");
  const [factionValue, setfactionValue] = useState("0");
  const AssetData = AccountStore.getSelectedAssetData();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleChange2 = (event) => {
    setfactionValue(event.target.value);
  };

  function checkvalid(asset) {
    var assetFaction =
      AccountStore.selectedAsset.name === "lands"
        ? asset?.stakedData?.type
        : asset?.stakedData?.faction;
    if (
      (asset.rarity === value || value === "All") &&
      (parseInt(factionValue) === parseInt(assetFaction) ||
        factionValue === "0")
    )
      return true;
    else return false;
  }

  function checkClaimable(asset) {
    var to_claim = asset.stakedData.to_claim;
    for (var i = 0; i < to_claim.length; i++) {
      var amount = parseFloat(to_claim[i].split(" ")[0]);
      if (amount !== 0) return true;
    }
    return false;
  }
  return (
    <div className="menu">
      <ContentBox
        title="Inventory"
        subtitle={"Manage " + AccountStore.selectedAsset.name}
      >
        {AccountStore.get_loading() ? (
          <Preloader />
        ) : (
          <div className="asset-inventory-content">
            <div className="asset-inventory-content-buttons">
              {selectedtype === "staked" ? (
                <button
                  className="statistics-button"
                  onClick={() => {
                    setSelectedtype("unstaked");
                  }}
                >
                  Unstaked
                </button>
              ) : (
                <>
                  <button
                    className="statistics-button"
                    onClick={() => {
                      setSelectedtype("staked");
                    }}
                  >
                    Staked
                  </button>
                </>
              )}

              {AccountStore.selectedAsset.name === "lands" ? (
                <>
                  <button
                    className="statistics-button"
                    onClick={() => {
                      FunctionStore.claimLands(
                        AssetData.stakedAssets
                          .filter((a) => checkClaimable(a))
                          .map((asset) => asset.asset_id)
                      );
                    }}
                  >
                    CLAIM ALL
                  </button>
                </>
              ) : (
                <></>
              )}
              <div className="statistics-button">
                <select
                  value={factionValue}
                  onChange={handleChange2}
                  className="statistics-optiontext"
                >
                  <option value="0" className="statistics-button2">
                    All
                  </option>
                  <option value="1" className="statistics-button2">
                    Zombie
                  </option>
                  <option value="2" className="statistics-button2">
                    Reborn
                  </option>
                  <option value="3" className="statistics-button2">
                    Hunter
                  </option>
                </select>
              </div>
              <label className="statistics-button">
                <select
                  value={value}
                  onChange={handleChange}
                  className="statistics-optiontext"
                >
                  <option className="statistics-button2" value="All">
                    <span> All </span>
                  </option>
                  <option className="statistics-button2" value="Common">
                    <span> Common </span>
                  </option>
                  <option className="statistics-button2" value="Uncommon">
                    <span> Uncommon </span>
                  </option>
                  <option className="statistics-button2" value="Rare">
                    <span> Rare </span>
                  </option>
                  <option className="statistics-button2" value="Epic">
                    <span> Epic </span>
                  </option>
                  <option className="statistics-button2" value="Legendary">
                    <span> Legendary </span>
                  </option>
                  <option className="statistics-button2" value="Mythic">
                    <span> Mythic </span>
                  </option>
                </select>{" "}
              </label>

              {selectedtype === "staked" ? (
                <button
                  className="statistics-button"
                  onClick={() => {
                    FunctionStore.deregisterAsset(
                      AssetData.stakedAssets
                        .filter((asset) => asset.raid === 0)
                        .map((asset) => asset.asset_id)
                    );
                  }}
                >
                  Deregister All
                </button>
              ) : (
                <>
                  <button
                    className="statistics-button"
                    onClick={() => {
                      FunctionStore.registerAsset(
                        AssetData.unStakedAssets.map((asset) => asset.asset_id)
                      );
                    }}
                  >
                    Register All
                  </button>
                </>
              )}
            </div>
            {AccountStore.selectedAsset.name === "moons" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "10px",
                  marginBottom: "10px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h1
                  className="statistics-optiontext"
                  style={{
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    border: "1px solid white",
                    padding: "10px",
                  }}
                >
                  Active Boost :{" "}
                  {AccountStore.getMoonBoostInfo()?.total_boost || 0} %
                </h1>
                <button
                  className="statistics-button"
                  onClick={() => FunctionStore.setbooster()}
                >
                  Activate
                </button>{" "}
                <h1
                  className="statistics-optiontext"
                  style={{
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    border: "1px solid white",
                    padding: "10px",
                  }}
                >
                  {AccountStore.getMoonBoostInfo()?.end_time == 0
                    ? "Inactive"
                    : "Active until " +
                      new Date(
                        (AccountStore.getMoonBoostInfo()?.end_time + 86400) *
                          1000
                      ).toLocaleString()}
                  <span
                    className="statistics-optiontext"
                    style={{
                      width: "fit-content",
                      color: "red",
                    }}
                  >
                    {""}
                    {AccountStore.getMoonBoostInfo()?.end_time != 0 &&
                      "Reset: " +
                        new Date(
                          (AccountStore.getMoonBoostInfo()?.end_time +
                            86400 * 7) *
                            1000
                        ).toLocaleString()}{" "}
                  </span>{" "}
                </h1>
              </div>
            )}
            <div className="asset-inventory-content-slider">
              <AssetsSlider>
                {selectedtype === "staked" ? (
                  AssetData.stakedAssets.map((asset, index) => {
                    return checkvalid(asset) ? (
                      <SwiperSlide key={asset.asset_id}>
                        <div className="assets-slider-item wallet-slider-item">
                          <h4 className="assets-slider-item-title">
                            {asset.name}
                            {AccountStore.selectedAsset.name === "lands" ? (
                              <>
                                <img
                                  src={require(`../../assets/toc/${asset.stakedData.type}.png`)}
                                  className="assets-slider-item-sub2"
                                  alt="tree nft"
                                />
                              </>
                            ) : (
                              <></>
                            )}
                          </h4>
                          <h2 className="assets-slider-item-sub">
                            {asset.asset_id}
                            {AccountStore.selectedAsset.name !== "moons" &&
                            asset.stakedData?.faction ? (
                              <img
                                className="assets-slider-raidicon"
                                src={require(`../../assets/toc/${asset.stakedData.faction}.png`)}
                                alt=""
                              />
                            ) : (
                              <></>
                            )}
                          </h2>
                          <h6 className="assets-slider-item-sub">
                            {asset.rarity?.toUpperCase()}
                          </h6>
                          <div className="assets-slider-item-image-wrapper">
                            {asset.video && !AccountStore.getMode() ? (
                              <video
                                src={`https://atomichub-ipfs.com/ipfs/${asset.video}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                loop
                                muted
                                autoPlay
                              />
                            ) : asset.img ? (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.imgback}`}
                              />
                            ) : (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${asset.imgback}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.imgback}`}
                              />
                            )}
                          </div>

                          <div className="assets-slider-item-buttons">
                            {asset.stakedData.raid_id === 0 ? (
                              <button
                                className="assets-slider-item-button"
                                onClick={() => {
                                  AccountStore.setRaidAsset(
                                    AccountStore.selectedAsset.name,
                                    asset
                                  );
                                  AccountStore.setStage("raid");
                                }}
                              >
                                {" "}
                                Select
                              </button>
                            ) : asset.stakedData.to_claim ? (
                              <>
                                <h1 className="assets-slider-item-sub">
                                  CLAIMABLE:<br></br>
                                  {asset.stakedData.to_claim.filter((a) =>
                                    a.includes("RADS")
                                  )}
                                  <br />
                                  {asset.stakedData.to_claim.filter((a) =>
                                    a.includes("ZOMB")
                                  )}
                                </h1>
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    FunctionStore.claimLands([asset.asset_id]);
                                  }}
                                >
                                  Claim
                                </button>
                              </>
                            ) : AccountStore.selectedAsset.name !== "moons" ? (
                              <h1 className="assets-slider-item-sub">
                                RAIDING- ID:{asset.stakedData.raid_id}
                              </h1>
                            ) : (
                              <h1 className="assets-slider-item-sub">
                                RAID BOOST : {asset.stakedData.boost} %
                              </h1>
                            )}
                            <button
                              className="assets-slider-item-button"
                              onClick={() => {
                                FunctionStore.deregisterAsset([asset.asset_id]);
                              }}
                            >
                              Unstake
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <></>
                )}

                {AssetData.unStakedAssets.length !== 0 &&
                selectedtype === "unstaked" ? (
                  AssetData.unStakedAssets.map((asset, index) => {
                    return checkvalid(asset) ? (
                      <SwiperSlide key={asset.asset_id}>
                        <div className="assets-slider-item wallet-slider-item">
                          <h4 className="assets-slider-item-title">
                            {asset.name}
                          </h4>
                          <h2 className="assets-slider-item-sub2">
                            {asset.asset_id}
                          </h2>
                          <div className="assets-slider-item-image-wrapper">
                            {asset.video && !AccountStore.getMode() ? (
                              <video
                                src={`https://atomichub-ipfs.com/ipfs/${asset.video}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                loop
                                autoPlay
                                muted
                              />
                            ) : (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                className={`assets-slider-item-image `}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.backimg}`}
                              />
                            )}
                          </div>
                          <div className="assets-slider-item-buttons">
                            <button
                              className="assets-slider-item-button"
                              onClick={() => {
                                FunctionStore.registerAsset([asset.asset_id]);
                              }}
                            >
                              Register
                            </button>
                          </div>
                        </div>
                      </SwiperSlide>
                    ) : (
                      <></>
                    );
                  })
                ) : (
                  <></>
                )}
              </AssetsSlider>
            </div>
          </div>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Statistics);
