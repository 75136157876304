import { Swiper } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";

export default function AssetsSlider({ children, assetsAmount, sliderStyles }) {
  return (
    <div className="assets-slider">
      <Swiper
        modules={[Navigation]}
        slidesPerView={assetsAmount < 4 ? assetsAmount : 4}
        grabCursor={true}
        centeredSlides={false}
        navigation
        spaceBetween={window.innerWidth <= 400 ? -50 : -70}
        className={sliderStyles}
        autoHeight={false}>
        {children}
      </Swiper>
    </div>
  );
}
