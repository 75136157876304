import { makeAutoObservable, toJS } from "mobx";
import FunctionStore from "./FunctionStore";

class AccountStore {
  constructor() {
    makeAutoObservable(this);
  }
  endpoint = this.getWaxEndpointList()[0].value;
  aa_endpoint = this.getAAEndpointList()[0].value;
  stage = "login";
  stage_history = [];
  intermediate_stage = null;
  userLevel = null;
  accountAddress = null;
  maxRaids = 50;
  mobileMode = false;
  userBalance = [];
  userWalletBalance = [];
  selectedAsset = {};
  loading = false;
  zoomed = false;
  raid_counts = 0;
  boost_types = [{ name: "No Boost" }];
  configs = {
    explore_types: [],
    nfts: [],
    general: [],
  };
  raid_state = {
    type: 1,
    boost: 0,
    faction: 0,
    lands: {
      schema: "lands",
    },
    characters: {
      schema: "characters",
    },
    companions: {
      schema: "companions",
    },
    commanders: {
      schema: "commanders",
    },
    weapons: {
      schema: "weapons",
    },
  };
  shopData = [];
  userData = [];
  boosy = false;
  stakingAssets = [];
  wufMiners = [];
  wufConfigs = [];
  wufPools = [];
  stakingUserInfo = null;
  moonBoostInfo = null;
  userAssets = {
    characters: [],
    companions: [],
    commanders: [],
    weapons: [],
    lands: [],
    radrocks: [],
    moons: [],
  };
  raids = [];
  allListings = [];
  day_raidcosts = [];
  // getters:
  exchangetype = "RADS";

  getMode() {
    return this.mobileMode;
  }

  setMode(val) {
    this.mobileMode = val;
  }

  get_allListings() {
    return this.allListings;
  }
  setListings(val) {
    this.allListings = val;
  }

  getRaidsCost() {
    console.log(this.configs.general);
    let costs = this.configs.general[0]
      ? this.configs.general[0].day_radcost
      : "";
    if (costs === "") return "Calculating";
    let raid_count = this.raid_counts;
    if (raid_count === 0) return costs[0].value;
    let final_cost = 0;
    if (costs !== "") {
      for (let i = 0; i < costs.length; i++) {
        if (costs[i].key === raid_count + 1) {
          final_cost = costs[i].value;
        }
      }
    }
    if (final_cost === 0) final_cost = "MAX raids today!";
    return final_cost;
  }

  getRaidCostArray() {
    let costs = this.configs.general[0]
      ? this.configs.general[0].day_radcost
      : "";
    if (costs === "") return "Calculating";
    let raid_count = this.raid_counts;
    var final_costs = [];
    if (costs !== "") {
      for (let i = 0; i < costs.length; i++) {
        if (costs[i].key >= raid_count + 1) {
          final_costs.push(costs[i].value);
        }
      }
    }
    return final_costs;
  }

  get_endpoint() {
    return this.endpoint;
  }

  set_stakingAssets(value) {
    this.stakingAssets = value;
  }

  set_endpoint(value) {
    this.endpoint = value;
    FunctionStore.set_endpoint(value);
  }
  //check
  set_btypes(value) {
    var xx = value.slice();
    var zz = [];
    zz.push({
      name: "No Boost",
    });
    for (let i = 0; i < xx.length; i++) {
      zz.push(xx[i]);
    }
    this.boost_types = zz;
  }

  set_zoomed(value) {
    this.zoomed = value;
  }

  get_boosy() {
    return this.boosy;
  }

  set_boosy(value) {
    this.boosy = value;
  }

  get_aaendpoint() {
    return this.endpoint;
  }

  set_aaendpoint(value) {
    this.aa_endpoint = value;
    FunctionStore.set_aaendpoint(value);
  }

  get_loading() {
    return this.loading;
  }
  getRaids() {
    return this.raids;
  }
  setRaids(val) {
    this.raids = val;
  }
  set_loading(value) {
    this.loading = value;
  }
  set_exchangetype(value) {
    this.exchangetype = value;
  }
  setday_raidcost(value) {
    this.day_raidcosts = value;
  }
  setMoonBoostInfo(value) {
    console.log("mboost", value);
    this.moonBoostInfo = value;
  }

  getMoonBoostInfo() {
    console.log("mboost", this.moonBoostInfo);
    return this.moonBoostInfo;
  }

  getBalancewallet(type, sym) {
    var gg = "0 " + sym;
    var balances =
      type === "wallet" ? this.userWalletBalance : this.userBalance;

    for (let i = 0; i < balances.length; i++) {
      if (balances[i].includes(sym)) {
        return parseFloat(balances[i]).toFixed(4);
      }
    }
    return gg;
  }

  setuserWBalance(value) {
    this.userWalletBalance = value;
  }

  getUserLevel() {
    return this.userLevel;
  }

  getUserXP() {
    return this.userXP;
  }

  getSelectedLand() {
    return this.selectedLand;
  }

  getRaidState() {
    return this.raid_state;
  }
  setRaidobj(value) {
    console.log(value);
    for (let j = 0; j < value.length; j++) {
      var val = value[j];
      val.characters = this.findNFT("characters", val.id);
      val.commanders = this.findNFT("commanders", val.id);
      val.companions = this.findNFT("companions", val.id);
      val.weapons = this.findNFT("weapons", val.id);
      val.lands = this.findNFT("lands", val.id)
        ? this.findNFT("lands", val.id)
        : {
            schema: "lands",
          };
    }
    this.raids = value;
  }

  resetRaidObj() {
    var value = this.raid_state;
    var val = value;
    val.characters = {
      schema: "characters",
    };
    val.commanders = {
      schema: "commanders",
    };
    val.companions = {
      schema: "companions",
    };
    val.weapons = {
      schema: "weapons",
    };
    val.lands = {
      schema: "lands",
    };
    val.boost = 0;
    val.faction = 0;
    this.raid_state = val;
    this.getRaidsCost();
  }

  findNFT(type, id) {
    var data = this.userAssets[type];
    for (let i = 0; i < data.length; i++) {
      if (
        data[i].asset_id &&
        data[i].stakedData &&
        data[i].stakedData.raid_id === id
      ) {
        return data[i];
      }
    }
    return 0;
  }

  getRaidCounts() {
    var gg = this.raid_counts;
    return gg;
  }

  setRaidAsset(type, asset) {
    if (type === "all") {
      this.raid_state = asset;
    } else this.raid_state[type] = asset;
    console.log(this.raid_state);
  }
  setLandType(type) {
    this.raid_state.type = type;
  }
  setFactionType(type) {
    this.raid_state.faction = type;
  }
  setBoostType(val) {
    this.raid_state.boost = val;
  }
  getEndpoint() {
    return this.endpoint;
  }

  getSelectedAssetData() {
    const assets = this.userAssets[this.selectedAsset.name];
    console.log("moons", assets);
    const stakedAssets = assets.filter((asset) => asset.staked);
    const unStakedAssets = assets.filter((asset) => !asset.staked);
    return {
      assets,
      stakedAssets,
      unStakedAssets,
      stakedAssetCount: stakedAssets.length,
      unStakedAssetCount: unStakedAssets.length,
    };
  }

  getShopData() {
    return this.shopData;
  }

  getSelectedAsset() {
    return this.selectedAsset;
  }

  getUserData() {
    return this.userData;
  }

  getAccountAddress() {
    return this.accountAddress;
  }

  getStageHistory() {
    return this.stage_history;
  }

  getStage() {
    return this.stage;
  }

  getLevelData() {
    return this.userLevelData;
  }

  getRecipes() {
    return this.recipes;
  }

  getStakingAssets() {
    return this.stakingAssets;
  }
  getWufMiners() {
    return this.wufMiners;
  }
  setWufMiners(val) {
    this.wufMiners = val;
  }
  setWufConfigs(val) {
    this.wufConfigs = val;
  }
  getWufConfigs() {
    return this.wufConfigs;
  }
  setWufPools(val) {
    this.wufPools = val;
  }
  getWufPools() {
    return this.wufPools;
  }
  getConfig() {
    return this.configs;
  }

  getUserAssets() {
    return this.userAssets;
  }

  getIntermidiateStage() {
    return this.intermediate_stage;
  }

  getcharacters(staked) {
    return staked
      ? this.userAssets.characters.filter(
          (asset) => asset.staked && asset.active
        )
      : this.userAssets.characters.filter((asset) => asset.active);
  }

  getcompanions(staked) {
    return staked
      ? this.userAssets.companions.filter(
          (asset) => asset.staked && asset.active
        )
      : this.userAssets.companions.filter((asset) => asset.active);
  }

  getcommanders(staked) {
    return staked
      ? this.userAssets.commanders.filter(
          (asset) => asset.staked && asset.active
        )
      : this.userAssets.commanders.filter((asset) => asset.active);
  }

  getweapons(staked) {
    return staked
      ? this.userAssets.weapons.filter((asset) => asset.staked && asset.active)
      : this.userAssets.weapons.filter((asset) => asset.active);
  }

  getLand(land_id) {
    return this.userAssets.lands.filter(
      (asset) => asset.asset_id === land_id
    )[0];
  }

  // setters:

  clearUserBalance() {
    this.userBalance = [];
  }

  setUserAssets(
    characters,
    companions,
    lands,
    commanders,
    weapons,
    radrocks,
    moons = []
  ) {
    this.userAssets.characters = characters;
    this.userAssets.companions = companions;
    this.userAssets.lands = lands;
    this.userAssets.commanders = commanders;
    this.userAssets.weapons = weapons;
    this.userAssets.radrocks = radrocks;
    if (moons.length > 0) {
      this.userAssets.moons = moons;
    }
  }

  setUserXP(val) {
    this.userXP = val;
  }

  setRecipes(val) {
    this.recipes = val;
  }

  setShopData(val) {
    this.shopData = val;
  }

  setActiveUserAssets(newAssets) {
    this.userAssets = newAssets;
  }

  setIntermidiateStage(val) {
    this.intermediate_stage = val;
  }

  clearIntermidiateStage() {
    this.intermediate_stage = null;
  }

  changeAccountAddress(addr) {
    this.accountAddress = addr;
  }

  setUserData(val) {
    this.userData = val;
  }

  setConfig(val) {
    this.userAssets.config = val;
  }

  split_raids(val) {
    var raids = 0;
    var ts = 0;
    for (let i = 0; i < val.length; i++) {
      if (val[i].includes("RAIDS")) {
        raids = parseInt(val[i]);
      } else if (val[i].includes("TMS")) {
        ts = parseInt(val[i]);
      }
    }
    if (ts !== 0) {
      console.log(ts);
      var current = new Date().getTime() / 1000;
      var last_day = 1664755200 + ts * 86400;
      var tr = current - last_day;
      var time = tr / 86400;
      var days = parseInt(time);
      console.log(days);
      if (days >= 1) raids = 0;
    }
    this.raid_counts = raids;
  }

  setUserBalance(val) {
    this.userBalance = val;
    this.split_raids(val);
  }

  setSelectedLand(val) {
    this.selectedLand = val;
    this.userAssets.lands.forEach((land) => {
      land.selected = land.asset_id === val.asset_id;
    });
  }

  setUserLands(val) {
    this.userAssets.lands = val;
  }

  setStage(val) {
    if (val === "rads_exchange") {
      this.stage = "token_exchange";
      this.stage_history.push("token_exchange");
      this.exchangetype = "RADS";
    } else if (val === "zomb_exchange") {
      this.stage = "token_exchange";
      this.stage_history.push("token_exchange");
      this.exchangetype = "ZOMB";
      this.set_exchangetype("ZOMB");
    } else {
      this.stage = val;
      this.stage_history.push(val);
      if (val === "menu") {
        this.clearStageHistory();
      }
    }
  }

  setSelectedAsset(type, name, group_name) {
    this.selectedAsset = {
      type,
      name,
      group_name,
    };
  }

  setSelectedAssetID(id, type) {
    var chck = false;
    for (let i = 0; i < this.raid_obj.length; i++) {
      if (this.raid_obj[i].key === type) {
        this.raid_obj[i].value = id;
        chck = true;
      }
    }
    if (!chck) {
      var gg = {
        key: type,
        value: id,
      };
      this.raid_obj.push(gg);
    }
    console.log(this.raid_obj[0].value);
  }

  setUserLevelData(val) {
    this.userLevelData = val;
  }

  setConfigType(type, val) {
    console.log(val);
    if (type === "nfts") {
      this.configs.nfts = val;
    }
    if (type === "explores") {
      this.configs.explore_types = val;
    }
    if (type === "general") {
      this.configs.general = val;
      this.getRaidsCost();
    }
  }
  setPreviousStage() {
    this.stage_history.pop();
    this.stage =
      this.stage_history.length > 0
        ? this.stage_history[this.stage_history.length - 1]
        : "menu";
  }

  clearStageHistory() {
    this.stage_history = [];
  }

  getAAEndpointList() {
    return [
      {
        label: "atomic-wax.tacocrypto.io",
        value: "https://atomic-wax.tacocrypto.io",
      },
      {
        label: "wax-aa.eosdac.io",
        value: "https://wax-aa.eosdac.io",
      },
      {
        label: "wax.api.atomicassets.io",
        value: "https://wax.api.atomicassets.io",
      },
      {
        label: "atomic.wax.eosrio.io",
        value: "https://atomic.wax.eosrio.io",
      },
      {
        label: "api.wax-aa.bountyblok.io",
        value: "https://api.wax-aa.bountyblok.io",
      },
      {
        label: "atomic-api.wax.cryptolions.io",
        value: "https://atomic-api.wax.cryptolions.io",
      },
      {
        label: "aa-wax-public1.neftyblocks.com",
        value: "https://aa-wax-public1.neftyblocks.com",
      },
      {
        label: "aa.dapplica.io ",
        value: "https://aa.dapplica.io",
      },
      {
        label: "wax-atomic-api.eosphere.io ",
        value: "https://wax-atomic-api.eosphere.io",
      },
      {
        label: "pro.waxgalaxy.io",
        value: "https://pro.waxgalaxy.io",
      },
    ];
  }

  getWaxEndpointList() {
    return [
      {
        label: "wax.api.eosnation.io",
        value: "https://wax.api.eosnation.io",
      },
      {
        label: "api-wax.tacocrypto.io",
        value: "https://api-wax.tacocrypto.io",
      },
      {
        label: "wax.eosusa.io",
        value: "https://wax.eosusa.io",
      },
      {
        label: "api.wax.bountyblok.io",
        value: "https://api.wax.bountyblok.io",
      },
      {
        label: "wax.greymass.com",
        value: "https://wax.greymass.com",
      },
      {
        label: "api.waxsweden.org",
        value: "https://api.waxsweden.org",
      },
      {
        label: "wax.dapplica.io",
        value: "https://wax.dapplica.io",
      },
      {
        label: "wax.eosphere.io",
        value: "https://wax.eosphere.io",
      },
      {
        label: "wax.cryptolions.io",
        value: "https://wax.cryptolions.io",
      },
      {
        label: "pro.waxgalaxy.io",
        value: "https://pro.waxgalaxy.io",
      },
    ];
  }

  clearAllData() {
    this.stage = "login";
    this.stage_history = [];
    this.intermediate_stage = null;

    this.accountAddress = null;
    this.userBalanceRADS = null;
    this.userBalance = [];
    this.selectedLand = null;
    this.selectedAsset = {};
    this.configs = {
      explore_types: [],
      nfts: [],
      general: [],
    };
    this.userData = [];
    this.userLevelData = {};
    this.raids = [];
    this.userAssets = {
      characters: [],
      companions: [],
      commanders: [],
      weapons: [],
      lands: [],
      radrocks: [],
    };
  }
}

export default new AccountStore();
