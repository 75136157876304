import "./index.sass";
import { observer } from "mobx-react";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-button.svg";
import Modal from "../modal";
import StoreContext from "../../store/RootStore";

function BoostModal({ modalOpened, setModalOpened }) {
  const { AccountStore, FunctionStore } = StoreContext();
  return (
    <Modal opened={modalOpened} setOpened={setModalOpened} className="modal">
      <div
        className="boost-modal"
        onClick={() => {
          setModalOpened(false);
        }}
      >
        <CloseIcon
          className="boost-modal-close"
          onClick={() => {
            setModalOpened(false);
          }}
        />
        <video
          src={require(`../../assets/toc/LOADINGSCREEN.mp4`)}
          alt="boost icon"
          className="boost-modal-img"
          autoPlay={true}
          loop={true}
        />
        <p className="boost-modal-text">In Progress..</p>
      </div>
    </Modal>
  );
}

export default observer(BoostModal);
