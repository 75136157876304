import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import { useState } from "react";
import Preloader from "../../components/preloader";
import BoostModal from "../../components/boostModal";

import { toJS } from "mobx"; // Import toJS from MobX
import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";

function Raid() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [modalOpened, setModalOpened] = useState(false);

  function nftCard(asset) {
    var cs = asset.schema === "characters" ? "characternftPop" : "cnftpop";
    var sub = asset.schema === "characters" ? "characternft-sub" : "cnft-sub";
    var closez =
      asset.schema === "characters"
        ? "characternft-crossbtn"
        : "extranft-crossbtn";
    if (asset.schema === "characters") cs = "characternftPop";
    if (asset.schema === "weapons") cs = "cnftpop";
    if (asset.img || asset.video) {
      return (
        <>
          {asset.video && !AccountStore.getMode() ? (
            <>
              <video
                autoPlay
                loop
                muted
                src={`https://atomichub-ipfs.com/ipfs/${asset.video}`}
                className={cs}
                alt=""
                onClick={
                  AccountStore.getRaidState().id
                    ? {}
                    : () => {
                        AccountStore.setSelectedAsset(
                          asset.schema,
                          asset.schema,
                          asset.schema
                        );
                        AccountStore.setStage("statistics");
                      }
                }
              />
            </>
          ) : (
            <>
              <img
                src={
                  asset.img
                    ? `https://atomichub-ipfs.com/ipfs/${asset.img}`
                    : `https://atomichub-ipfs.com/ipfs/${asset.imgback}`
                }
                className={cs}
                alt=""
                onClick={() => {
                  AccountStore.setSelectedAsset(
                    asset.schema,
                    asset.schema,
                    asset.schema
                  );
                  AccountStore.setStage("statistics");
                }}
              />
            </>
          )}
          {asset.schema === "commanders" ||
          asset.schema === "characters" ||
          asset.schema === "weapons" ? (
            <img
              className="characternft-raidicon"
              src={require(`../../assets/toc/${asset.stakedData.faction}.png`)}
              alt=""
            />
          ) : (
            <></>
          )}

          <h4 className={sub}>
            {FunctionStore.get_asset_boost(asset.stakedData)}
          </h4>
          <button
            className={closez}
            onClick={() => {
              AccountStore.setRaidAsset(asset.schema, {
                schema: asset.schema,
              });
            }}
          ></button>
        </>
      );
    } else {
      return (
        <>
          <img
            src={require(`../../assets/toc/RAID--07.png`)}
            className={cs}
            alt=""
            onClick={() => {
              AccountStore.setSelectedAsset(
                asset.schema,
                asset.schema,
                asset.schema
              );
              AccountStore.setStage("statistics");
            }}
          />
        </>
      );
    }
  }

  function shiftLand(direction) {
    if (direction === "left") {
      var next = AccountStore.getRaidState().faction - 1;
      if (next < 1) next = 3;
      AccountStore.setFactionType(next);
    } else if (direction === "right") {
      var nextz = AccountStore.getRaidState().faction + 1;
      if (nextz > 3) nextz = 1;
      AccountStore.setFactionType(nextz);
    }
  }

  function shiftType(direction) {
    if (direction === "left") {
      var next = AccountStore.getRaidState().type - 1;
      if (next < 1) next = 3;
      AccountStore.setLandType(next);
    } else if (direction === "right") {
      var nextz = AccountStore.getRaidState().type + 1;
      if (nextz > 3) nextz = 1;
      AccountStore.setLandType(nextz);
    }
  }

  function autoSelect() {
    var assets = AccountStore.userAssets;
    var characters = assets.characters
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var characterFaction = "none";
    var weapons = assets.weapons
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var commanders = assets.commanders
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var companions = assets.companions
      .sort(
        (a, b) =>
          FunctionStore.split_boosts(b.stakedData)?.total -
          FunctionStore.split_boosts(a.stakedData)?.total
      )
      .filter((c) => c.stakedData?.raid_id === 0);
    var selected = [];
    var selectedCharacter = null;
    var selectedWeapon = null;
    var selectedCommander = null;
    var selectedCompanion = null;
    for (var i = 0; i < characters.length; i++) {
      var boosts = FunctionStore.split_boosts(characters[i].stakedData);
      var rboost = boosts.rboost;
      var nboost = boosts.nboost;
      var tboost = boosts.tboost;
      if (rboost === 0) continue;
      var faction = characters[i].stakedData.faction;
      characterFaction = faction;
      selectedCharacter = characters[i];
      selectedCharacter.power = rboost;
      break;
    }
    for (var i = 0; i < weapons.length; i++) {
      var weaponFaction = weapons[i]?.stakedData?.faction;
      if (!weaponFaction || characterFaction === "none") continue;
      if (weaponFaction === characterFaction) {
        selectedWeapon = weapons[i];
        selectedWeapon.power = FunctionStore.split_boosts(
          weapons[i].stakedData
        );
        break;
      }
    }
    for (var i = 0; i < commanders.length; i++) {
      var commanderFaction = commanders[i]?.stakedData?.faction;
      if (!commanderFaction || characterFaction === "none") continue;
      if (commanderFaction === characterFaction) {
        selectedCommander = commanders[i];
        selectedCommander.power = FunctionStore.split_boosts(
          commanders[i].stakedData
        );
        break;
      }
    }

    selectedCompanion = companions.length > 0 ? companions[0] : null;
    selected.push(selectedCharacter);
    selected.push(selectedWeapon);
    if (selectedCommander) selected.push(selectedCommander);
    if (selectedCompanion) selected.push(selectedCompanion);
    console.log(selected);
    for (var i = 0; i < selected.length; i++) {
      console.log(selected[i]?.power?.toString());
    }
    if (selectedCharacter)
      AccountStore.setRaidAsset("characters", selectedCharacter);
    if (selectedWeapon) AccountStore.setRaidAsset("weapons", selectedWeapon);
    if (selectedCommander)
      AccountStore.setRaidAsset("commanders", selectedCommander);
    if (selectedCompanion)
      AccountStore.setRaidAsset("companions", selectedCompanion);
  }

  function shiftBoost(direction) {
    if (!AccountStore.getRaidState().boost) AccountStore.setBoostType(0);
    if (direction === "left") {
      var next = AccountStore.getRaidState().boost - 1;
      if (next < 0) next = AccountStore.boost_types.length - 1;
      AccountStore.setBoostType(next);
    } else if (direction === "right") {
      var nextz = AccountStore.getRaidState().boost + 1;
      if (nextz > AccountStore.boost_types.length - 1) nextz = 0;
      AccountStore.setBoostType(nextz);
    }
    console.log(AccountStore.getRaidState().boost);
  }

  function int_toName(value, type) {
    if (!AccountStore.getRaidState().type) AccountStore.getRaidState().type = 1;
    if (type === "faction") {
      if (value === 1) return "Zombie";
      if (value === 2) return "Reborn";
      if (value === 3) return "Hunter";
    } else if (type === "types") {
      if (value === 1) return "Zomb Heavy";
      if (value === 2) return "Equal";
      if (value === 3) return "NFT Heavy";
    } else if (type === "boosts") {
      if (AccountStore.boost_types[value])
        return AccountStore.boost_types[value];
      else return AccountStore.boost_types[0];
    }
  }

  function landnftz() {
    return (
      <div className="landnft">
        <img
          src={require(`../../assets/toc/RAID-_LEFTARROW.png`)}
          className="leftLand"
          alt="tree nft"
          onClick={() => {
            shiftLand("left");
          }}
        />
        <img
          src={
            AccountStore.getRaidState().faction
              ? require(`../../assets/toc/${
                  AccountStore.getRaidState().faction
                }.png`)
              : require(`../../assets/toc/1.png`)
          }
          className="middleLand"
          alt="tree nft"
        />
        <img
          src={require(`../../assets/toc/RAID-_RIGHTARROW.png`)}
          className="leftLand"
          alt="tree nft"
          onClick={() => {
            shiftLand("right");
          }}
        />

        <div
          src={require(`../../assets/toc/RAID--24.png`)}
          className="textLand"
          alt="tree nft"
        >
          <img
            src={require(`../../assets/toc/RAID-_LEFTARROW.png`)}
            className="textLand2"
            alt="tree nft"
            onClick={() => {
              shiftType("left");
            }}
          />
          <h4 className="textLand4">
            {int_toName(AccountStore.getRaidState().type, "types")}
          </h4>
          <img
            src={require(`../../assets/toc/RAID-_RIGHTARROW.png`)}
            className="textLand2"
            alt="tree nft"
            onClick={() => {
              shiftType("right");
            }}
          />
        </div>
      </div>
    );
  }
  function getBalanceSymb() {
    var returnTyp = AccountStore.userBalance.filter(
      (x) =>
        x.split(" ")[1] ===
        int_toName(AccountStore.getRaidState().boost, "boosts").boost.split(
          " "
        )[1]
    );
    if (returnTyp.length > 0) return returnTyp[0].split(" ")[0];
    else return "0";
  }

  function landbox() {
    return (
      <>
        <div className="radsBox">
          <div className="radcostBox">
            <h4 className="slottext4">TOTAL RADS COST:</h4>
            <h4 className="slottext4">
              {AccountStore.getRaidsCost()}
              <img
                className="costimg"
                src={require(`../../assets/toc/rads.png`)}
                alt="tree nft"
              />
            </h4>
            <h4
              className="slottext4"
              style={{
                marginTop: "5px",
                padding: "5px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              {toJS(AccountStore.getWufMiners()).length > 0 && (
                <img
                  className="costimg"
                  src="https://wax.alcor.exchange/_nuxt/img/wuf_wuffi.40c3bd1.png"
                  alt="tree nft"
                />
              )}
              Active
            </h4>
          </div>
          <div className="boostBox">
            <div className="boostBox2" alt="tree nft">
              <img
                src={require(`../../assets/toc/RAID-_LEFTARROW.png`)}
                className="textLand2"
                alt="tree nft"
                onClick={() => {
                  shiftBoost("left");
                }}
              />
              <h4 className="textLand4">
                {int_toName(AccountStore.getRaidState().boost, "boosts").name}
                {int_toName(AccountStore.getRaidState().boost, "boosts")
                  .name !== "No Boost" ? (
                  <img
                    src={
                      int_toName(AccountStore.getRaidState().boost, "boosts")
                        .faction
                        ? require(`../../assets/toc/${
                            int_toName(
                              AccountStore.getRaidState().boost,
                              "boosts"
                            ).faction
                          }.png`)
                        : require(`../../assets/toc/0.png`)
                    }
                    className="boostimg5"
                    alt="tree nft"
                  />
                ) : (
                  <></>
                )}
              </h4>
              <img
                src={require(`../../assets/toc/RAID-_RIGHTARROW.png`)}
                className="textLand2"
                alt="tree nft"
                onClick={() => {
                  shiftBoost("right");
                }}
              />
            </div>
            {int_toName(AccountStore.getRaidState().boost, "boosts").name !==
            "No Boost" ? (
              <>
                {" "}
                <div className="slottextv">
                  <h4 className="slottextx">BALANCE:{getBalanceSymb()}</h4>
                  <h4 className="slottextx">
                    Zomb :{" "}
                    {int_toName(AccountStore.getRaidState().boost, "boosts")
                      .rb /
                      10000 +
                      100}
                    %
                  </h4>
                  <h4 className="slottextx">
                    NFT :{" "}
                    {int_toName(AccountStore.getRaidState().boost, "boosts")
                      .nb / 10000}
                    %
                  </h4>
                </div>
                <img
                  className="boostimg"
                  src={`https://atomichub-ipfs.com/ipfs/${
                    int_toName(AccountStore.getRaidState().boost, "boosts").img
                  }`}
                  alt="failed"
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="ccbox-item-image5">
            <img
              src={require(`../../assets/toc/RAID--16.png`)}
              className="ccbox-item-image5"
              alt="tree nft"
              onClick={() => {
                FunctionStore.beginRaid(
                  AccountStore.getRaidState(),
                  AccountStore.getRaidState().faction
                    ? AccountStore.getRaidState().faction
                    : 1,
                  AccountStore.getRaidState().type
                    ? AccountStore.getRaidState().type
                    : 1
                );
                setModalOpened(true);
              }}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="menu">
      <ContentBox>
        {AccountStore.get_loading() ? (
          <>
            <Preloader />
          </>
        ) : (
          <>
            <BoostModal
              modalOpened={modalOpened}
              setModalOpened={setModalOpened}
            />
            <div className="raidtopinfo">
              <div className="raidheader" />
              <div className="raidslots">
                <h4 className="slottext-main">
                  SLOT:{" "}
                  {AccountStore.getRaidState().id
                    ? AccountStore.getRaidState().index
                    : parseInt(AccountStore.getRaidCounts()) + 1}
                </h4>
              </div>
              <button
                className="autoselect"
                onClick={() => {
                  autoSelect();
                }}
              >
                <h4 className="slottext-main">AUTO SELECT</h4>
              </button>
              <div className="initraid">
                <h4 className="slottext-extra">
                  <h4 className="slottext-extra">
                    {AccountStore.getRaidState().id
                      ? "IN BATTLE"
                      : "INITIALIZING"}{" "}
                  </h4>
                </h4>
              </div>
              <div className="initraid">
                <h4 className="slottext-extra">ENDS IN 3:00:00</h4>
              </div>
            </div>
            <div className="raidpage">
              <div character>
                <div className="characterimg">
                  {AccountStore.getRaidState().characters ? (
                    <>{nftCard(AccountStore.getRaidState().characters)}</>
                  ) : (
                    <></>
                  )}
                </div>
                <h4 className="ctext">
                  TOTC ENTERPRISES V1.0<br></br>
                  c:/boonahgraphics.exe
                </h4>
              </div>

              <div className="extranft">
                <div className="cnft">
                  <div className="cnftcards-w">
                    {AccountStore.getRaidState().weapons ? (
                      nftCard(AccountStore.getRaidState().weapons)
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="cnftcards-com">
                    {AccountStore.getRaidState().commanders ? (
                      nftCard(AccountStore.getRaidState().commanders)
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="cnftcards-co">
                    {AccountStore.getRaidState().companions ? (
                      nftCard(AccountStore.getRaidState().companions)
                    ) : (
                      <></>
                    )}
                  </div>
                  {landnftz()}
                </div>
                {landbox()}
              </div>
            </div>
          </>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Raid);
