import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import { useEffect, useState } from "react";
import "./index.sass";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";

function Shop() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [activeTab, setActiveTab] = useState("packs");
  const [packInputData, setPackInputData] = useState([]);
  const [TypeData, setTypeData] = useState([]);
  const [popped, setPopped] = useState(false);

  return (
    <div className="shop-page">
      <ContentBox title="">
        <div className="shop-wrapper">
          {popped ? (
            <>
              <div className="shop-popup">
                <div className="shop-tabs">
                  <button
                    className={`shop-tab ${
                      activeTab === "all" ? "shop-tab-active" : ""
                    }`}
                    onClick={() => {
                      setActiveTab("all");
                    }}
                  >
                    ALL
                  </button>
                  <button
                    className={`shop-tab ${
                      activeTab === "packs" ? "shop-tab-active" : ""
                    }`}
                    onClick={() => {
                      setActiveTab("packs");
                    }}
                  >
                    PACKS
                  </button>
                  <button
                    className={`shop-tab ${
                      activeTab === "items"
                        ? "shop-tab-active shop-tab-active"
                        : ""
                    }`}
                    onClick={() => {
                      setActiveTab("items");
                    }}
                  >
                    ITEMS
                  </button>
                  <button
                    className={`shop-tab ${
                      activeTab === "art"
                        ? "shop-tab-active shop-tab-active"
                        : ""
                    }`}
                    onClick={() => {
                      setActiveTab("art");
                    }}
                  >
                    ART
                  </button>
                  <button
                    className={`shop-tab ${
                      activeTab === "misc"
                        ? "shop-tab-active shop-tab-active"
                        : ""
                    }`}
                    onClick={() => {
                      setActiveTab("misc");
                    }}
                  >
                    MISC
                  </button>
                </div>
                <div className="shop-content">
                  <AssetsSlider>
                    {AccountStore.getShopData().filter(
                      (shopData) =>
                        shopData.type === activeTab || activeTab === "all"
                    ).length > 0 ? (
                      AccountStore.getShopData()
                        .filter(
                          (shopData) =>
                            shopData.type === activeTab || activeTab === "all"
                        )
                        .map((asset, index) => {
                          return (
                            <>
                              <SwiperSlide key={index}>
                                <div className="shop-item-wrapper">
                                  {!asset.video ? (
                                    <img
                                      src={
                                        asset.img && !asset.video
                                          ? `https://atomichub-ipfs.com/ipfs/${asset.img}`
                                          : `https://atomichub-ipfs.com/ipfs/${asset.video}`
                                      }
                                      alt={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                      className="shop-item-img"
                                    />
                                  ) : (
                                    <video
                                      src={
                                        asset.video
                                          ? `https://atomichub-ipfs.com/ipfs/${asset.video}`
                                          : require(`../../assets/toc/WEAPONBUTTON.png`)
                                      }
                                      alt={`https://atomichub-ipfs.com/ipfs/${asset.img}`}
                                      className="shop-item-img"
                                    />
                                  )}
                                  <h6 className="shop-item-title">
                                    {asset.name ? asset.name : ""}
                                  </h6>
                                  <h6 className="shop-item-level">
                                    AVAILABLE:{" "}
                                    {asset.available >= 0
                                      ? asset.available
                                      : "Unlimited"}
                                  </h6>
                                  <form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                    }}
                                    className="shop-item-form"
                                  >
                                    <input
                                      type="number"
                                      onChange={(e) => {
                                        setPackInputData((prevState) => {
                                          return {
                                            ...prevState,
                                            [asset.id]: e.target.value,
                                          };
                                        });
                                      }}
                                      value={packInputData[asset.id] || ""}
                                      className="shop-item-form-input"
                                      placeholder="Qty. (1 by default)"
                                    />

                                    {asset.price !== "0.0000 X" &&
                                    asset.price !== "0.00 X" ? (
                                      <span
                                        onClick={() => {
                                          setTypeData((result) => {
                                            return {
                                              ...result,
                                              [asset.id]: "rads",
                                            };
                                          });
                                        }}
                                        className={
                                          TypeData[asset.id] !== "rads" &&
                                          TypeData[asset.id] === "zomb"
                                            ? "shop-item-level"
                                            : "shop-item-level-active"
                                        }
                                      >
                                        {parseFloat(asset.price).toFixed(2) *
                                          (packInputData[asset.id] || 1) +
                                          " RADS"}{" "}
                                        {
                                          <img
                                            src={require("../../assets/toc/rads.png")}
                                            alt="RADS Token"
                                            className="shop-item-timg"
                                          />
                                        }
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {asset.price2 !== "0.0000 X" ? (
                                      <span
                                        onClick={() => {
                                          setTypeData((result) => {
                                            return {
                                              ...result,
                                              [asset.id]: "zomb",
                                            };
                                          });
                                        }}
                                        className={
                                          TypeData[asset.id] !== "zomb"
                                            ? "shop-item-level"
                                            : "shop-item-level-active"
                                        }
                                      >
                                        {parseFloat(asset.price2).toFixed(2) *
                                          (packInputData[asset.id] || 1) +
                                          " ZOMB"}{" "}
                                        {
                                          <img
                                            src={require("../../assets/toc/zomb.png")}
                                            alt="RADS Token"
                                            className="shop-item-timg"
                                          />
                                        }
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </form>
                                </div>
                                <button
                                  type="submit"
                                  className="shop-item-button"
                                  onClick={() => {
                                    if (
                                      asset.price === "0.0000 X" ||
                                      asset.price === "0.00 X"
                                    ) {
                                      setTypeData((result) => {
                                        return {
                                          ...result,
                                          [asset.id]: "zomb",
                                        };
                                      });
                                    }
                                    FunctionStore.buyShopItem(
                                      asset.id,
                                      packInputData[asset.id]
                                        ? packInputData[asset.id]
                                        : 1,
                                      TypeData[asset.id] === "zomb"
                                        ? asset.price2
                                        : asset.price !== "0.0000 X" &&
                                          asset.price !== "0.00 X"
                                        ? asset.price
                                        : asset.price2,
                                      TypeData[asset.id] === "zomb"
                                        ? "ZOMB"
                                        : asset.price !== "0.0000 X" &&
                                          asset.price !== "0.00 X"
                                        ? "RADS"
                                        : "ZOMB"
                                    );
                                  }}
                                ></button>
                              </SwiperSlide>
                            </>
                          );
                        })
                    ) : (
                      <></>
                    )}
                  </AssetsSlider>
                  <button
                    className="close"
                    onClick={() => {
                      setPopped(false);
                    }}
                  ></button>
                </div>
              </div>
            </>
          ) : (
            <>
              <button
                className="popupz"
                onClick={() => {
                  setPopped(true);
                }}
              ></button>
            </>
          )}
        </div>
      </ContentBox>
    </div>
  );
}

export default observer(Shop);
