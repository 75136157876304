import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";
import "../Raid_Menu/index.sass";

function Invent() {
  const { AccountStore, FunctionStore } = StoreContext();

  const assetTypes = [
    //change this if more Invent are added
    {
      name: "characters",
      img: "CHARACTERBUTTON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=characters&sort=created&state=1&symbol=WAX",
    },
    {
      name: "companions",
      img: "COMPANIONBUTTON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=companions&sort=created&state=1&symbol=WAX",
    },
    {
      name: "weapons",
      img: "WEAPONBUTTON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=weapons&sort=created&state=1&symbol=WAX",
    },
    {
      name: "commanders",
      img: "COMMANDERBUTTON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=commanders&sort=created&state=1&symbol=WAX",
    },
    {
      name: "lands",
      img: "LANDSBUTTON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=lands&sort=created&state=1&symbol=WAX",
    },
    {
      name: "moons",
      img: "MOON.png",
      buylink:
        "https://wax.atomichub.io/market?collection_name=cryptodeadnw&order=desc&schema_name=boosters&sort=created&state=1&symbol=WAX",
    },
  ];

  return (
    <div className="menu">
      <ContentBox title="Inventory" subtitle="Select Type">
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "space-evenly",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "nowrap",
          }}
        >
          {assetTypes.map((type) => (
            <>
              <div className="menu-option">
                <div className="menu-option-image">
                  <img
                    src={require(`../../assets/toc/${type.img}`)}
                    alt="trees"
                    style={{ width: "100%" }}
                    onClick={() => {
                      AccountStore.setSelectedAsset(
                        type.name,
                        type.name,
                        "Inventory"
                      );
                      AccountStore.setStage("statistics");
                    }}
                  />
                  {type.name == "moons" && (
                    <h4
                      style={{
                        fontFamily: "game-over",
                        fontWeight: 600,
                        fontSize: "1vw",
                        color: "white",
                        textAlign: "center",
                      }}
                    >
                      MOONS
                    </h4>
                  )}
                </div>
                <a
                  href={type.buylink}
                  target="_blank"
                  rel="noreferrer"
                  className="inventory-option"
                >
                  BUY
                </a>
              </div>
            </>
          ))}
        </div>
      </ContentBox>
    </div>
  );
}

export default observer(Invent);
