import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import "swiper/css";
import "swiper/css/navigation";
import "./index.sass";
import "../Raid_Menu/index.sass";
import { useEffect, useState } from "react";
import STimer from "../../components/stimer";
import Preloader from "../../components/preloader";

function Invent() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [scavInfo, setscavInfo] = useState([]);
  const [scavTime, setscavTime] = useState(0);

  useEffect(() => {
    getInfo();
  }, []);

  async function getInfo() {
    var info = await FunctionStore.getscavInfo();
    setscavInfo(info);
    setscavTime(
      scavInfo.length === 0 ? 0 : parseInt(scavInfo[0]?.last_claim) + 86400
    );
    var now = new Date();
    var timeDiff = scavTime - now.getTime();
    console.log(scavTime);
    console.log(now.getTime());
    console.log(timeDiff);
  }

  async function scavenge() {
    var endTime = new Date((parseInt(scavInfo[0]?.last_claim) + 86400) * 1000);
    const diff = Date.parse(endTime) - Date.parse(new Date());
    var seconds = Math.floor((diff / 1000) % 60);
    var minutes = Math.floor((diff / 1000 / 60) % 60);
    var hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    if (hours > 0 || minutes > 0 || seconds > 0) {
      FunctionStore.handleError(
        "You can scavenge after " +
          hours +
          " hours " +
          minutes +
          " minutes " +
          seconds +
          " seconds"
      );
      return;
    } else {
      var info = await FunctionStore.scavenge();
      if (info) {
        await getInfo();
      }
    }
  }

  function breakResult(result) {
    var outputs = result.split(" ");
    var nft = outputs.length > 2 ? true : false;
    var rads = outputs[0]?.split("rads:") ? outputs[0].split("rads:")[1] : 0;
    var zomb = outputs[1]?.split("zomb:") ? outputs[1].split("zomb:")[1] : 0;
    if (result === "holdup") {
      nft = false;
      rads = -1;
      zomb = 0;
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "2vw",
            color: "white",
            marginTop: "2vh",
            marginBottom: "2vh",
            fontFamily: "game-over",
            fontStyle: "oblique",
            fontWeight: "800",
          }}
        >
          Last Scavenge:
        </div>
        <div
          style={{
            fontSize: "2vw",
            color: "white",
            marginTop: "2vh",
            marginBottom: "2vh",
            fontFamily: "game-over",
            fontStyle: "oblique",
            fontWeight: "800",
          }}
        >
          {rads === -1 ? "Processing" : parseFloat(rads) / 10000}{" "}
          {
            <img
              src={require("../../assets/toc/rads.png")}
              alt="complete"
              style={{
                width: "auto",
                height: "5vh",
                borderRadius: "10px",
                marginLeft: "10px",
                marginBottom: "10px",
                position: "absolute",
                zIndex: "1000",
              }}
            />
          }
        </div>
        <div
          style={{
            fontSize: "2vw",
            color: "white",
            marginTop: "2vh",
            marginBottom: "2vh",
            fontFamily: "game-over",
            fontStyle: "oblique",
            fontWeight: "800",
          }}
        >
          {parseFloat(zomb) / 10000}
          <img
            src={require("../../assets/toc/zomb.png")}
            alt="complete"
            style={{
              width: "auto",
              height: "5vh",
              borderRadius: "10px",
              marginLeft: "10px",
              marginBottom: "10px",
              position: "absolute",
              zIndex: "1000",
            }}
          />{" "}
        </div>
        {nft ? (
          <div
            style={{
              fontSize: "2vw",
              color: "white",
              marginTop: "2vh",
              marginBottom: "2vh",
              fontFamily: "game-over",
              fontStyle: "oblique",
              fontWeight: "800",
              backgroundImage:
                "url(" + require("../../assets/toc/scav/EGGWINNAH.png") + ")",
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              width: "20vw",
              height: "20vh",
            }}
          >
            {"+ RADS FRAGMENT NFT!"}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }

  return (
    <div className="menu">
      <ContentBox title="">
        <img
          src={require("../../assets/toc/scav/scavengingbg.jpg")}
          alt="complete"
          style={{
            width: "105vw",
            height: "100vh",
            borderRadius: "10px",
            marginLeft: "-2.5vw",
            marginBottom: "10px",
            position: "absolute",
          }}
        />
        {AccountStore.loading ? (
          <Preloader />
        ) : (
          <>
            <div
              style={{
                position: "absolute",
                width: "30vw",
                height: "20vh",
                background: "rgba(0,0,0,0.7)",
                marginLeft: "10vw",
                marginTop: "2vh",
                borderRadius: "10px",
                marginBottom: "10px",
                zIndex: "100",
                backgroundImage:
                  "url(" +
                  require("../../assets/toc/scav/RADSTIMER2.png") +
                  ")",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                display: "flex",
                justifyContent: "left",
                alignItems: "left",
              }}
            >
              {scavInfo.length === 0 ? (
                <>{"Loading..."}</>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                    marginTop: "1vh",
                    marginRight: "10vw",
                  }}
                >
                  <STimer
                    endTime={
                      new Date(
                        (parseInt(scavInfo[0]?.last_claim) + 86400) * 1000
                      )
                    }
                  />
                </div>
              )}
            </div>

            <img
              src={require("../../assets/toc/scav/scavbutt.png")}
              style={{
                width: "30vw",
                height: "20vh",
                marginLeft: "35vw",
                marginTop: "50vh",
                borderRadius: "10px",
                marginBottom: "10px",
                position: "absolute",
                background: "rgba(0,0,0,0.7)",
                border: "none",
                outline: "black solid 0.2vw",
                cursor: "pointer",
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                zIndex: "100",
              }}
              onClick={() => scavenge()}
            ></img>

            {
              //Create a box which shows the last scavenged result
              scavInfo.length === 0 ? (
                <>{"Loading..."}</>
              ) : (
                <div
                  style={{
                    width: "30vw",
                    height: "auto",
                    marginLeft: "65vw",
                    marginTop: "5vh",
                    borderRadius: "10px",
                    marginBottom: "10px",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(0,0,0,0.8)",
                    flexDirection: "column",
                    zIndex: "100",
                    border: "none",
                    outline: "green solid 0.2vw",
                    cursor: "pointer",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "2vw",
                      marginTop: "2vh",
                      color: "white",
                      fontSize: "3vw",
                    }}
                  >
                    {breakResult(scavInfo[0]?.last_result)}
                  </div>
                </div>
              )
            }
          </>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Invent);
