import "./index.sass";
import { observer } from "mobx-react";
import ContentBox from "../../components/contentBox";
import { useEffect, useState } from "react";
import StoreContext from "../../store/RootStore";
import "../Menu/index.sass";
import Preloader from "../../components/preloader";

function TokenExchange() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [depositAmount, setDepositAmount] = useState("");
  const [swapAmount, setSwapAmount] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [exchangeType, setExchangeType] = useState(AccountStore.exchangetype);

  return (
    <div className="menu">
      <ContentBox title="Token Exchange">
        {AccountStore.get_loading() ? (
          <Preloader />
        ) : (
          <div className="menu-options">
            <div className="token-exchange-item">
              <h4 className="token-exchange-item-title">
                Deposit {exchangeType}
              </h4>
              <form
                className="token-exchange-item-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (Number(depositAmount) > 0) {
                    FunctionStore.depositRADS(
                      Number(depositAmount),
                      exchangeType
                    );
                  } else {
                    FunctionStore.handleError("Incorrect Amount.");
                  }
                }}
              >
                <div className="token-exchange-item-form-input-wrapper">
                  <input
                    type="number"
                    className="token-exchange-item-form-input"
                    placeholder="Amount"
                    value={depositAmount}
                    onChange={(e) => {
                      setDepositAmount(e.target.value);
                    }}
                  />
                </div>
                <h6 className="token-exchange-item-title">
                  Wallet balance:{" "}
                  <span className="token-exchange-green-text">
                    {AccountStore.getBalancewallet("wallet", exchangeType)}
                  </span>
                </h6>
                <button
                  className="token-exchange-item-form-submit"
                  type="submit"
                >
                  DEPOSIT
                </button>
              </form>
            </div>
            <div className="token-exchange-item">
              <h4 className="token-exchange-item-title">
                Withdraw (10% FEES) {exchangeType}
              </h4>
              <form
                className="token-exchange-item-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  if (Number(withdrawAmount) > 0) {
                    FunctionStore.withdrawRADS(
                      Number(withdrawAmount),
                      exchangeType
                    );
                  } else {
                    FunctionStore.handleError("Incorrect Amount.");
                  }
                }}
              >
                <div className="token-exchange-item-form-input-wrapper">
                  <input
                    type="number"
                    className="token-exchange-item-form-input"
                    placeholder="Amount"
                    value={withdrawAmount}
                    onChange={(e) => {
                      setWithdrawAmount(e.target.value);
                    }}
                  />
                </div>
                <h6 className="token-exchange-item-title">
                  Game balance:{" "}
                  <span className="token-exchange-green-text">
                    {AccountStore.getBalancewallet("game", exchangeType)}
                  </span>
                </h6>
                <button
                  className="token-exchange-item-form-submit"
                  type="submit"
                >
                  WITHDRAW
                </button>
              </form>
            </div>
            {/* {
                    exchangeType==="RADS"?
                    <div
                        className="token-exchange-item"
                        >
                        <h6 className="token-exchange-item-title">Swap RADS-ZOMB</h6>
                        <form className="token-exchange-item-form" onSubmit={(e) => {
                            e.preventDefault();
                            if (Number(swapAmount) > 0) {
                                FunctionStore.depositRADS(Number(swapAmount));
                            } else {
                                FunctionStore.handleError("Incorrect Amount.");
                            }
                        }}>
                         <div className="token-exchange-item-form-input-wrapper">
                                <input type="number" className="token-exchange-item-form-input" placeholder="Amount" value={swapAmount} onChange={(e)=>{setSwapAmount(e.target.value)}}/>
                            </div>
                            <button className="token-exchange-item-form-submit" type="submit">
                            <h6 className="token-exchange-item-title">
                            SWAP
                                </h6>
                                </button>
                        </form>
                    </div> 
                    :<></>
                    } */}
          </div>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(TokenExchange);
