import { observer } from "mobx-react";
import StoreContext from "../../store/RootStore";
import ContentBox from "../../components/contentBox";
import AssetsSlider from "../../components/assetsSlider";
import { SwiperSlide } from "swiper/react";
import { useEffect, useState } from "react";
import "../Invent/index.sass";
import Preloader from "../../components/preloader";

function Rocks() {
  const { AccountStore, FunctionStore } = StoreContext();
  const [selectedtype, setSelectedtype] = useState("staked");
  const AssetData = AccountStore.getSelectedAssetData();

  async function claimAll() {
    await FunctionStore.sendRock(
      AssetData.assets
        .filter((asset) => !asset.staked)
        .map((asset) => asset.asset_id)
    );
    await FunctionStore.claimRock(
      AssetData.assets
        .filter((asset) => asset.staked && asset.result !== 0)
        .map((asset) => asset.asset_id)
    );
  }

  return (
    <div className="menu">
      <ContentBox
        title="Inventory"
        subtitle={"Manage " + AccountStore.selectedAsset.name}
      >
        {AccountStore.get_loading() ? (
          <Preloader />
        ) : (
          <div className="asset-inventory-content">
            <div className="asset-inventory-content-buttons">
              <button
                className="asset-inventory-content-button"
                onClick={() => {
                  FunctionStore.claimAllRocks(AssetData.assets);
                }}
              >
                Claim All
              </button>
            </div>
            <div className="asset-inventory-content-slider">
              <AssetsSlider>
                {AssetData.assets ? (
                  AssetData.assets.map((asset, index) => {
                    return (
                      <SwiperSlide key={asset.asset_id + index}>
                        <div className="assets-slider-item wallet-slider-item">
                          <h2 className="assets-slider-item-title">
                            {asset.asset_id}
                          </h2>
                          <div className="assets-slider-item-image-wrapper">
                            q
                            {asset.template && asset.template.video ? (
                              <video
                                src={`https://atomichub-ipfs.com/ipfs/${asset.template.video}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/${asset.template.img}`}
                                autoPlay
                                loop
                                muted
                              />
                            ) : asset.template && asset.template.img ? (
                              <img
                                src={`https://atomichub-ipfs.com/ipfs/${asset.template.img}`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/QmXyrq74T5c3U5B7GhoDkMPANb9KqXUA4CWCSYQAY3mLBZ`}
                              />
                            ) : (
                              <video
                                src={`https://atomichub-ipfs.com/ipfs/QmXyrq74T5c3U5B7GhoDkMPANb9KqXUA4CWCSYQAY3mLBZ`}
                                className={`assets-slider-item-image`}
                                alt={`https://atomichub-ipfs.com/ipfs/QmXyrq74T5c3U5B7GhoDkMPANb9KqXUA4CWCSYQAY3mLBZ`}
                                autoPlay
                                loop
                                muted
                              />
                            )}
                          </div>
                          <div className="assets-slider-item-buttons">
                            <h1 className="assets-slider-item-sub">
                              {asset.result !== 0
                                ? "Claim Now!"
                                : "Waiting for result"}
                            </h1>{" "}
                            {asset.staked && asset.result !== 0 ? (
                              <>
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    FunctionStore.claimRock([asset.asset_id]);
                                  }}
                                >
                                  {" "}
                                  Claim
                                </button>
                              </>
                            ) : !asset.staked ? (
                              <>
                                <button
                                  className="assets-slider-item-button"
                                  onClick={() => {
                                    FunctionStore.sendRock([asset.asset_id]);
                                  }}
                                >
                                  {" "}
                                  Redeem
                                </button>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })
                ) : (
                  <></>
                )}
              </AssetsSlider>
            </div>
          </div>
        )}
      </ContentBox>
    </div>
  );
}

export default observer(Rocks);
