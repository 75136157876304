import "./index.sass"
import {observer} from "mobx-react";
import {ReactComponent as CloseIcon} from "../../assets/icons/close-button.svg";
import Modal from "../modal";
import StoreContext from "../../store/RootStore";

function LevelUpModal({modalOpened, setModalOpened, selectedAsset, selectedAssetConfig}) {
    const {AccountStore, FunctionStore} = StoreContext();
    return (
        <Modal opened={modalOpened} setOpened={setModalOpened}>
            <div className="level-modal">
                <CloseIcon className="level-modal-close" onClick={()=>{setModalOpened(false)}}/>
                <h6 className="level-modal-title">Level upgrade</h6>
                <img src={require("../../assets/icons/level-up-icon.png")} alt="level-up" className="level-modal-img"/>
                {AccountStore.getSelectedAsset().type !== "trees" && selectedAsset && selectedAsset.type !== "animal" ? //re-do this for animals, shelter level up wont work
                    <>
                        <span className="level-modal-cost">Level Up Fees: {selectedAssetConfig ? selectedAssetConfig.cost_level.filter(cost => cost.level === Number(selectedAsset ? selectedAsset.level.charAt(selectedAsset.level.length - 1) : 1) + 1)[0].count : "loading..."}</span>
                        <p className="level-modal-text">Are you sure? The asset will be upgraded to level {selectedAsset ? Number(selectedAsset.level.charAt(selectedAsset.level.length - 1)) + 1 : "loading..."}</p>
                    </> :
                    <>
                        <span className="level-modal-cost">Level Up Fees: {selectedAssetConfig ? selectedAssetConfig.cost_level.filter(cost => Number(cost.in_name.charAt(cost.in_name.length - 1)) <= Number(selectedAsset ? selectedAsset.level.charAt(selectedAsset.level.length - 1) : 1) + 1).pop().in_qty : "loading..."}</span>
                        <p className="level-modal-text">Are you sure? The asset will be upgraded to level {selectedAsset ? Number(selectedAsset.level.charAt(selectedAsset.level.length - 1)) + 1 : "loading..."}</p>
                    </>
                }
                <button className="level-modal-button" onClick={()=>{
                    let levelUpAvailable = false;
                    let maxLevel;
                    switch (AccountStore.getSelectedAsset().type){
                        case "trees":
                        case "animals":
                        case "shelters":
                            maxLevel = AccountStore.getUserLevel().max_animal_level;
                            if (AccountStore.getUserLevel().max_animal_level > Number(selectedAsset.level.charAt(selectedAsset.level.length - 1))){
                                levelUpAvailable = true;
                            }
                            break;
                        case "machines":
                            maxLevel = AccountStore.getUserLevel().max_machine_level;
                            if (AccountStore.getUserLevel().max_machine_level > Number(selectedAsset.level.charAt(selectedAsset.level.length - 1))){
                                levelUpAvailable = true;
                            }
                            break;
                        case "crop_fields":
                            maxLevel = AccountStore.getUserLevel().max_cropf_level;
                            if (AccountStore.getUserLevel().max_cropf_level > Number(selectedAsset.level.charAt(selectedAsset.level.length - 1))){
                                levelUpAvailable = true;
                            }
                    }
                    if (levelUpAvailable) {
                        FunctionStore.levelUpAsset(selectedAsset.asset_id, selectedAsset.type || AccountStore.getSelectedAsset().type);
                    } else {
                        FunctionStore.handleError(`Cannot level up due to level restrictions. Max allowed ${selectedAsset.type || AccountStore.getSelectedAsset().type} level is ${maxLevel}.`)
                    }
                }}>Level up</button>
            </div>
        </Modal>
    )
}

export default observer(LevelUpModal)